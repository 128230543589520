<form [formGroup]="formGroup">
    <nuc-form-field label="Static content ruleset name">
        <nuc-input formControlName="name" placeholder="Static content ruleset name"></nuc-input>
    </nuc-form-field>

    <nuc-form-field label="Engine types">
        <nuc-dropdown-multiselect formControlName="engineTypes"
                                  placeholder="Select one or more engine types"
                                  [items]="engineTypes"
                                  (dataChange)="resetDesignLibraries($event)">
        </nuc-dropdown-multiselect>
    </nuc-form-field>

    <nuc-form-field label="Libraries">
        <nuc-dropdown-multiselect formControlName="designLibraries"
                                  placeholder="Select one or more design libraries"
                                  [items]="designLibraries"
                                  [searchable]="true"
                                  [total]="designLibrariesTotalItems"
                                  (requestData)="requestDesignLibraries($event)"
                                  [required]="true">
        </nuc-dropdown-multiselect>
    </nuc-form-field>
</form>
