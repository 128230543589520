import {Completion, CompletionResult} from '@codemirror/autocomplete';
import {VariantModel} from '../../../../models/api/variant.model';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';

export class AutoCompleteFactory {
    private dataFieldOptions: Completion[];
    private variantOptions: Completion[];

    constructor(private dataFields: IDropdownItem<string>[], private variants: VariantModel[]) {
        this.dataFieldOptions = dataFields.map(dataField => ({label: dataField.getTitle(), type: 'text', info: 'Data field'}));
        this.variantOptions = variants.map(variant => ({label: variant.name, type: 'text', info: 'Variant'}));
    }

    public dataFieldsAutocomplete = (context): CompletionResult | null => {
        const {pos} = context;

        // Check if the cursor is inside a string within brackets
        const result = this.isCursorInsideStringInBrackets(context);
        if (result.cursorInsideStringInBrackets) {
            if (result.entry === 2) {
                return {
                    from: pos - result.textBeforeCursor.length,
                    options: this.variantOptions.filter(option => option.label.startsWith(result.textBeforeCursor)) as Completion[]
                };
            }
            // Default
            return {
                from: pos - result.textBeforeCursor.length,
                options: this.dataFieldOptions.filter(option => option.label.startsWith(result.textBeforeCursor)) as Completion[]
            };
        }

        return null; // No suggestions
    }

    private isCursorInsideStringInBrackets(context): { cursorInsideStringInBrackets: boolean, entry?: number, textBeforeCursor?: string } {
        const {state, pos} = context;
        const line = state.doc.lineAt(pos);
        const relativePos = pos - line.from;

        const beforeCursor = line.text.slice(0, relativePos);
        const afterCursor = line.text.slice(relativePos);

        // Find the last unmatched opening bracket before the cursor
        const lastOpenBracket = beforeCursor.lastIndexOf('[');
        if (lastOpenBracket === -1) return {cursorInsideStringInBrackets: false}; // No opening bracket

        // Find the first closing bracket after the cursor
        const nextCloseBracket = afterCursor.indexOf(']');
        if (nextCloseBracket === -1) return {cursorInsideStringInBrackets: false}; // No closing bracket

        // Extract content between the brackets
        const bracketContent = line.text.slice(
            lastOpenBracket + 1,
            relativePos + nextCloseBracket
        );

        // Match for quoted strings in the bracket content
        const quoteMatch = bracketContent.match(/(["'])(?:(?=(\\?))\2.)*?\1/);
        if (!quoteMatch) return {cursorInsideStringInBrackets: false}; // No string in brackets

        // Check if the cursor is inside the string
        const stringStart = lastOpenBracket + 1 + bracketContent.indexOf(quoteMatch[0]);
        const stringEnd = stringStart + quoteMatch[0].length;

        const cursorInsideStringInBrackets = relativePos > stringStart && relativePos < stringEnd;
        const textBeforeCursor = beforeCursor.slice(lastOpenBracket + 2);

        // Get all brackets positions (from and to)
        const matches = [...beforeCursor.matchAll(/[\[\]]/g)];
        const allMatches = matches.map(match => ({
            match: match[0], // The matched text
            from: match.index, // Start position of the match
            to: match.index + match[0].length // End position of the match
        }));

        // Get entry of consecutive bracket pairs:
        // | is the cursor position in ['test']['test2'] = ['test|']
        // Cursor position is in the second entry
        let last;
        for (const match of allMatches.reverse()) {
            if (!last || last.from === match.to) last = match;
        }

        return {
            cursorInsideStringInBrackets,
            textBeforeCursor,
            entry: allMatches.indexOf(last) + 1
        };
    }
}
