import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {ApiService} from './api.service';
import {OAuthClientModel} from '../../models/api/oauth-client.model';

@Injectable({providedIn: 'root'})
export class OAuthClientApiService extends ApiService<OAuthClientModel> {
    constructor() {
        super([ApiConstants.API_GROUP_OAUTH_CLIENTS], OAuthClientModel);
    }
}
