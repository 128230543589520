import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AppConstants} from '../../../app.constants';
import {ITabBarItem} from '@relayter/rubber-duck/lib/modules/tab-bar/config/tab-bar-item.interface';
import {TabBarRoutableItem} from './tab-bar-routable-item.model';
import {RLBaseComponent} from '../../../components/rl-base-component/rl-base.component';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {startWith} from 'rxjs';

@Component({
    selector: 'rl-team-settings-component',
    templateUrl: 'team-settings.component.html',
    styleUrls: ['team-settings.component.scss']
})

export class TeamSettingsComponent extends RLBaseComponent {
    private generalTab = new TabBarRoutableItem(
        'General',
        [`/${AppConstants.CONTEXT_URL.SETTINGS}`, AppConstants.CONTEXT_URL.TEAM_SETUP, AppConstants.CONTEXT_URL.GENERAL_INFORMATION],
        [AppConstants.PERMISSIONS.GET_TEAM_DETAILS]
    );

    private connectionsTab = new TabBarRoutableItem(
        'Connections', [`/${AppConstants.CONTEXT_URL.SETTINGS}`, AppConstants.CONTEXT_URL.TEAM_SETUP, AppConstants.CONTEXT_URL.CONNECTIONS],
        [AppConstants.PERMISSIONS.GET_CONNECTIONS]
    );

    private masterBriefingsTab = new TabBarRoutableItem(
        'Master briefing', [`/${AppConstants.CONTEXT_URL.SETTINGS}`, AppConstants.CONTEXT_URL.TEAM_SETUP, AppConstants.CONTEXT_URL.MASTER_BRIEFING],
        [AppConstants.PERMISSIONS.GET_CAMPAIGN_ITEM]
    );

    private oAuthClientsTab = new TabBarRoutableItem(
        'OAuth clients',
        [`/${AppConstants.CONTEXT_URL.SETTINGS}`, AppConstants.CONTEXT_URL.TEAM_SETUP, AppConstants.CONTEXT_URL.OAUTH_CLIENTS],
        [AppConstants.PERMISSIONS.GET_OAUTH_CLIENTS]
    );

    public tabs = [this.generalTab, this.connectionsTab, this.masterBriefingsTab, this.oAuthClientsTab];
    public activeTab: TabBarRoutableItem;

    constructor(private router: Router, private userIsAllowedToPipe: UserIsAllowedToPipe) {
        super();
        this.tabs = this.tabs.filter((tab) => this.userIsAllowedToPipe.transform(tab.permissions));

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map((event) => event.url),
                startWith(this.router.url),
                distinctUntilChanged(),
                takeUntilDestroyed(this.destroyRef)
            ).subscribe((url: string) => {
                this.activeTab = this.tabs.find((tab) => tab.url.join('/') === url) || this.tabs[0];
            });
    }

    public onActiveTabChanged(newTab: ITabBarItem): void {
        if (newTab) {
            this.activeTab = this.tabs.find((tab) => tab.title === newTab.title);
            this.router.navigate(this.activeTab.url, {skipLocationChange: false});
        }
    }
}
