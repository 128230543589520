import {inject, Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {BaseApiRequestService} from './base-api-request.service';
import {QueryParams} from '../../classes/query-params';
import {
    StaticContentRulesetModel,
    StaticContentRulesetPatchModel} from '../../modules/static-content-rulesets/models/api/static-content-ruleset.model';
import {Cursor} from '../api-cursor';
import {SortDirection} from '@angular/material/sort';
import {JobModel} from '../../models/api/job.model';
import {tap} from 'rxjs/operators';
import {MonitoredJobsService} from './monitored-updates/monitored-jobs.service';
import {Serialize} from 'cerialize';
import {StaticContentRulesetItemModel} from '../../modules/static-content-rulesets/models/api/static-content-ruleset-item.model';
import {StaticContentRulesetAssetItemModel} from '../../modules/static-content-rulesets/models/api/static-content-ruleset-asset-item.model';
import {StaticContentRulesetItemGroupModel} from '../../modules/static-content-rulesets/models/api/static-content-ruleset-item-group.model';
import {ItemUsedModel} from '../../models/api/item-used.model';

export enum EFormatRulesetJobs {
    FORMAT_RULESET_COPY_JOB = 'FORMAT_RULESET_COPY_JOB'
}

export interface IFormatRulesetCopyJobData {
    formatRulesetId: string;
}

type ItemModel = StaticContentRulesetItemModel | StaticContentRulesetItemGroupModel | StaticContentRulesetAssetItemModel;

@Injectable({
    providedIn: 'root'
})
export class StaticContentRulesetService extends BaseApiRequestService {
    private monitoredJobsService = inject(MonitoredJobsService);

    /**
     * Get a paged array of format rulesets
     *
     * @param {string} [libraryId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {Cursor} cursor
     * @param {string} sortProperty
     * @param {SortDirection} sortOrder
     * @param {string} search
     * @returns {Observable<ARPagedResponseDataModel<StaticContentRulesetModel>>}
     */
    public getStaticContentRulesets(libraryId?: string, limit?: number, offset?: number, cursor?: Cursor, sortProperty?: string,
                                    sortOrder?: SortDirection, search?: string): Observable<ARPagedResponseDataModel<StaticContentRulesetModel>> {

        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sortProperty, sortOrder)
            .setSearchParams(search)
            .addParam('libraryId', libraryId);

        if (cursor) {
            queryParams.setCursor(cursor);
        }

        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_STATIC_CONTENT_RULESETS], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;

        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, StaticContentRulesetModel);
        });
    }

    /**
     * Get a static content ruleset
     *
     * @param {string} formatRulesetId
     * @returns {Observable<ARPagedResponseDataModel<StaticContentRulesetModel>>}
     */
    public getStaticContentRuleset(formatRulesetId: string): Observable<StaticContentRulesetModel> {

        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_STATIC_CONTENT_RULESETS,
            formatRulesetId
        ]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, StaticContentRulesetModel);
        });
    }

    /**
     * Get static content ruleset usage
     *
     * @param {string} formatRulesetId
     * @returns {Observable<ARPagedResponseDataModel<ItemUsedModel>>}
     */
    public getStaticContentRulesetUsage(formatRulesetId: string): Observable<ItemUsedModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_STATIC_CONTENT_RULESETS,
            formatRulesetId,
            ApiConstants.API_METHOD_USAGE
        ]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable<ItemUsedModel>((obs) => {
            this.handleDetailResponse(options, obs, ItemUsedModel);
        });
    }

    /**
     * Delete a Static content ruleset
     *
     * @param {string} formatRulesetId
     * @returns {Observable<boolean>}
     */
    public deleteStaticContentRuleset(formatRulesetId: string): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_STATIC_CONTENT_RULESETS,
            formatRulesetId
        ]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;
        return new Observable<boolean>((obs) => {
            this.handleNoErrorResponse(options, obs);
        });
    }

    /**
     * Post a new static content ruleset
     *
     * @param {StaticContentRulesetPatchModel} formatRulesetId
     * @returns {Observable<StaticContentRulesetModel>}
     */
    public postStaticContentRuleset(formatRulesetId: StaticContentRulesetPatchModel): Observable<StaticContentRulesetModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_STATIC_CONTENT_RULESETS
        ]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = Serialize(formatRulesetId, StaticContentRulesetPatchModel);

        return new Observable<StaticContentRulesetModel>((obs) => {
            this.handleDetailResponse(options, obs, StaticContentRulesetModel);
        });
    }

    /**
     * Update a static content ruleset
     *
     * @param {string} formatRulesetId
     * @param {StaticContentRulesetPatchModel} formatRuleset
     * @returns {Observable<StaticContentRulesetModel>}
     */
    public patchStaticContentRuleset(formatRulesetId: string, formatRuleset: StaticContentRulesetPatchModel): Observable<StaticContentRulesetModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_STATIC_CONTENT_RULESETS,
            formatRulesetId
        ]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = Serialize(formatRuleset, StaticContentRulesetPatchModel);

        return new Observable<StaticContentRulesetModel>((obs) => {
            this.handleDetailResponse(options, obs, StaticContentRulesetModel);
        });
    }

    /**
     * Post a static content ruleset job
     *
     * @param {EFormatRulesetJobs} jobType
     * @param {Record<string, any>} jobData
     * @returns Observable<JobModel>
     */
    public postJob(jobType: EFormatRulesetJobs, jobData: IFormatRulesetCopyJobData): Observable<JobModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_STATIC_CONTENT_RULESETS,
            ApiConstants.API_METHOD_JOBS
        ]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {jobType, jobData};

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, JobModel);
        }).pipe(
            tap((job: JobModel) => this.monitoredJobsService.addItemToMonitor(job))
        );
    }

    /**
     * Get static content ruleset item
     *
     * @param {string} formatRulesetId
     * @param {string} formatRulesetItemId
     * @param {ItemModel} model
     * @returns Observable<ItemModel>
     */
    private getStaticContentRulesetItem<T extends ItemModel>(
        formatRulesetId: string,
        formatRulesetItemId: string,
        model: new (...args: any[]) => T): Observable<T> {

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_STATIC_CONTENT_RULESETS,
            formatRulesetId,
            new model().getApiPath(),
            formatRulesetItemId
        ]);

        const options = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => this.handleDetailResponse(options, obs, model));
    }

    /**
     * Update static content ruleset item
     *
     * @param {string} formatRulesetId
     * @param {string} formatRulesetItemId
     * @param {ItemModel} item
     * @param {ItemModel} model
     * @returns Observable<ItemModel>
     */
    private updateStaticContentRulesetItem<T extends ItemModel>(
        formatRulesetId: string,
        formatRulesetItemId: string,
        item: T,
        model: new (...args: any[]) => T): Observable<T> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_STATIC_CONTENT_RULESETS,
            formatRulesetId,
            new model().getApiPath(),
            formatRulesetItemId
        ]);

        const options = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PUT;
        options.url = url;
        options.body = item;
        return new Observable((obs) => this.handleDetailResponse(options, obs, model));
    }

    /**
     * Post static content ruleset item
     *
     * @param {string} formatRulesetId
     * @param {ItemModel} item
     * @param {ItemModel} model
     * @returns Observable<ItemModel>
     */
    private postStaticContentRulesetItem<T extends ItemModel>(
        formatRulesetId: string,
        item: ItemModel,
        model: new (...args: any[]) => T): Observable<T> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_STATIC_CONTENT_RULESETS,
            formatRulesetId,
            new model().getApiPath()
        ]);

        const options = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = item;
        return new Observable((obs) => {
            this.handleDetailResponse<ItemModel>(options, obs, model);
        });
    }

    /**
     * Delete a static content ruleset item
     *
     * @param {string} formatRulesetId
     * @param {string} formatRulesetItemId
     * @param {ItemModel} model
     * @returns Observable<boolean>
     */
    private deleteStaticContentRulesetItem<T extends ItemModel>(
        formatRulesetId: string,
        formatRulesetItemId: string,
        model: new (...args: any[]) => T): Observable<boolean> {
        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_STATIC_CONTENT_RULESETS,
            formatRulesetId,
            new model().getApiPath(),
            formatRulesetItemId
        ]);

        const options = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.DELETE;
        options.url = url;
        return new Observable((obs) => this.handleNoErrorResponse(options, obs));
    }

    /**
     * Get static content ruleset items/asset items
     *
     * @param {string} formatRulesetId
     * @param {number} [limit=20]
     * @param {number} [offset=0]
     * @param {ItemModel} model
     * @param {string} [sortProperty]
     * @param {SortDirection} [sortOrder]
     * @param {string} [search]
     * @param {string} [itemGroupId]
     * @returns Observable<ARPagedResponseDataModel<ItemModel>>
     */
    private getStaticContentRulesetItems<T extends ItemModel>(
        formatRulesetId: string,
        limit: number = 20,
        offset: number = 0,
        model: new (...args: any[]) => T,
        sortProperty?: string,
        sortOrder?: SortDirection,
        search?: string,
        itemGroupId?: string):
        Observable<ARPagedResponseDataModel<ItemModel>> {
        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sortProperty, sortOrder);

        if (search) {
            queryParams.addParam('search', search);
        }

        if (itemGroupId) {
            queryParams.addParam('itemGroupId', itemGroupId);
        }

        const url = ARApiUrlBuilderService.urlFromComponents([environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ApiConstants.API_GROUP_STATIC_CONTENT_RULESETS,
            formatRulesetId,
            new model().getApiPath()
        ], queryParams.getParams());

        const options = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => {
            this.handlePagedResponse(options, obs, model);
        });
    }

    /**
     * Get static content ruleset asset item
     *
     * @param {string} formatRulesetId
     * @param {string} formatRulesetItemId
     * @returns Observable<StaticContentRulesetAssetItemModel>
     */
    public getStaticContentRulesetAssetItem(formatRulesetId: string, formatRulesetItemId: string):
        Observable<StaticContentRulesetAssetItemModel> {
        return this.getStaticContentRulesetItem(formatRulesetId, formatRulesetItemId, StaticContentRulesetAssetItemModel) as
            Observable<StaticContentRulesetAssetItemModel>;
    }

    /**
     * Get static content ruleset library item
     *
     * @param {string} formatRulesetId
     * @param {string} formatRulesetItemId
     * @returns Observable<StaticContentRulesetItemModel>
     */
    public getStaticContentRulesetLibraryItem(formatRulesetId: string, formatRulesetItemId: string): Observable<StaticContentRulesetItemModel> {
        return this.getStaticContentRulesetItem(
            formatRulesetId, formatRulesetItemId, StaticContentRulesetItemModel) as Observable<StaticContentRulesetItemModel>;
    }

    /**
     * Get static content ruleset item group
     *
     * @param {string} formatRulesetId
     * @param {string} formatRulesetItemId
     * @returns Observable<StaticContentRulesetItemGroupModel>
     */
    public getStaticContentRulesetItemGroup(formatRulesetId: string, formatRulesetItemId: string): Observable<StaticContentRulesetItemGroupModel> {
        return this.getStaticContentRulesetItem(
            formatRulesetId,
            formatRulesetItemId,
            StaticContentRulesetItemGroupModel) as Observable<StaticContentRulesetItemGroupModel>;
    }

    /**
     * Update static content ruleset asset item
     *
     * @param {string} formatRulesetId
     * @param {string} itemId
     * @param {StaticContentRulesetAssetItemModel} item
     * @returns Observable<StaticContentRulesetAssetItemModel>
     */
    public updateStaticContentRulesetAssetItem(formatRulesetId: string, itemId: string, item: StaticContentRulesetAssetItemModel):
        Observable<StaticContentRulesetAssetItemModel> {
        return this.updateStaticContentRulesetItem(
            formatRulesetId,
            itemId,
            item,
            StaticContentRulesetAssetItemModel) as Observable<StaticContentRulesetAssetItemModel>;
    }

    /**
     * Update static content ruleset library item
     *
     * @param {string} formatRulesetId
     * @param {string} itemId
     * @param {StaticContentRulesetItemModel} item
     * @returns Observable<StaticContentRulesetItemModel>
     */
    public updateStaticContentRulesetLibraryItem(formatRulesetId: string, itemId: string, item: StaticContentRulesetItemModel)
        : Observable<StaticContentRulesetItemModel> {
        return this.updateStaticContentRulesetItem(
            formatRulesetId,
            itemId,
            item,
            StaticContentRulesetItemModel) as Observable<StaticContentRulesetItemModel>;
    }

    /**
     * Update static content ruleset item group
     * @param {string} formatRulesetId
     * @param {string} itemId
     * @param {StaticContentRulesetItemGroupModel} item
     * @returns Observable<StaticContentRulesetItemGroupModel>
     */
    public updateStaticContentRulesetItemGroup(formatRulesetId: string, itemId: string, item: StaticContentRulesetItemGroupModel):
        Observable<StaticContentRulesetItemGroupModel> {
        return this.updateStaticContentRulesetItem(
            formatRulesetId, itemId, item, StaticContentRulesetItemGroupModel) as Observable<StaticContentRulesetItemGroupModel>;
    }

    /**
     * Post a static content ruleset asset item
     *
     * @param {string} formatRulesetId
     * @param {StaticContentRulesetAssetItemModel} rulesetAssetItem
     * @returns Observable<StaticContentRulesetAssetItemModel>
     */
    public postStaticContentRulesetAssetItem(formatRulesetId: string, rulesetAssetItem: StaticContentRulesetAssetItemModel):
        Observable<StaticContentRulesetAssetItemModel> {
        return this.postStaticContentRulesetItem(
            formatRulesetId, rulesetAssetItem, StaticContentRulesetAssetItemModel) as Observable<StaticContentRulesetAssetItemModel>;
    }

    /**
     * Post a static content ruleset library item
     *
     * @param {string} formatRulesetId
     * @param {StaticContentRulesetItemModel} formatRulesetItem
     * @returns Observable<StaticContentRulesetItemModel>
     */
    public postStaticContentRulesetLibraryItem(formatRulesetId: string, formatRulesetItem: StaticContentRulesetItemModel):
        Observable<StaticContentRulesetItemModel> {
        return this.postStaticContentRulesetItem(
            formatRulesetId, formatRulesetItem, StaticContentRulesetItemModel) as Observable<StaticContentRulesetItemModel>;
    }

    /**
     * Post a static content ruleset item group
     *
     * @param {string} formatRulesetId
     * @param {StaticContentRulesetItemGroupModel} item
     * @returns Observable<StaticContentRulesetItemGroupModel>
     */
    public postStaticContentRulesetItemGroup(formatRulesetId: string, item: StaticContentRulesetItemGroupModel)
        : Observable<StaticContentRulesetItemGroupModel> {
        return this.postStaticContentRulesetItem(
            formatRulesetId, item, StaticContentRulesetItemGroupModel) as Observable<StaticContentRulesetItemGroupModel>;
    }

    /**
     * Delete a static content ruleset asset item
     *
     * @param {string} formatRulesetId
     * @param {string} formatRulesetItemId
     * @returns Observable<boolean>
     */
    public deleteStaticContentRulesetAssetItem(formatRulesetId: string, formatRulesetItemId: string): Observable<boolean> {
        return this.deleteStaticContentRulesetItem(formatRulesetId, formatRulesetItemId, StaticContentRulesetAssetItemModel);
    }

    /**
     * Delete a static content ruleset item
     *
     * @param {string} formatRulesetId
     * @param {string} formatRulesetItemId
     * @returns Observable<boolean>
     */
    public deleteStaticContentRulesetLibraryItem(formatRulesetId: string, formatRulesetItemId: string): Observable<boolean> {
        return this.deleteStaticContentRulesetItem(formatRulesetId, formatRulesetItemId, StaticContentRulesetItemModel);
    }

    /**
     * Delete a static content ruleset item group
     *
     * @param {string} formatRulesetId
     * @param {string} formatRulesetItemId
     * @returns Observable<boolean>
     */
    public deleteStaticContentRulesetItemGroup(formatRulesetId: string, formatRulesetItemId: string): Observable<boolean> {
        return this.deleteStaticContentRulesetItem(formatRulesetId, formatRulesetItemId, StaticContentRulesetItemGroupModel);
    }

    /**
     * Get static content ruleset asset items
     *
     * @param {string} formatRulesetId
     * @param {number} limit
     * @param {number} offset
     * @param {string} sortProperty
     * @param {SortDirection} sortOrder
     * @param {string} search
     * @returns Observable<ARPagedResponseDataModel<StaticContentRulesetAssetItemModel>>
     */
    public getStaticContentRulesetAssetItems(formatRulesetId: string,
                                             limit = 20,
                                             offset = 0,
                                             sortProperty?: string,
                                             sortOrder?: SortDirection,
                                             search?: string): Observable<ARPagedResponseDataModel<StaticContentRulesetAssetItemModel>> {
        return this.getStaticContentRulesetItems(formatRulesetId,
            limit,
            offset,
            StaticContentRulesetAssetItemModel,
            sortProperty,
            sortOrder,
            search) as Observable<ARPagedResponseDataModel<StaticContentRulesetAssetItemModel>>;
    }

    /**
     * Get static content ruleset library items
     *
     * @param {string} formatRulesetId
     * @param {number} limit
     * @param {number} offset
     * @param {string} sortProperty
     * @param {SortDirection} sortOrder
     * @param {string} search
     * @param {string} itemGroup
     * @returns Observable<ARPagedResponseDataModel<StaticContentRulesetItemModel>>
     */
    public getStaticContentRulesetLibraryItems(formatRulesetId: string,
                                               limit = 20,
                                               offset = 0,
                                               sortProperty?: string,
                                               sortOrder?: SortDirection,
                                               search?: string,
                                               itemGroup?: string): Observable<ARPagedResponseDataModel<StaticContentRulesetItemModel>> {
        return this.getStaticContentRulesetItems(formatRulesetId,
            limit,
            offset,
            StaticContentRulesetItemModel,
            sortProperty,
            sortOrder,
            search,
            itemGroup) as Observable<ARPagedResponseDataModel<StaticContentRulesetItemModel>>;
    }

    /**
     * Get static content ruleset item groups
     *
     * @param {string} formatRulesetId
     * @param {number} limit
     * @param {number} offset
     * @param {string} sortProperty
     * @param {SortDirection} sortOrder
     * @param {string} search
     * @returns Observable<ARPagedResponseDataModel<StaticContentRulesetItemGroupModel>>
     */
    public getStaticContentRulesetItemGroups(formatRulesetId: string,
                                             limit = 20,
                                             offset = 0,
                                             sortProperty?: string,
                                             sortOrder?: SortDirection,
                                             search?: string): Observable<ARPagedResponseDataModel<StaticContentRulesetItemGroupModel>> {
        return this.getStaticContentRulesetItems(formatRulesetId,
            limit,
            offset,
            StaticContentRulesetItemGroupModel,
            sortProperty,
            sortOrder,
            search) as Observable<ARPagedResponseDataModel<StaticContentRulesetItemGroupModel>>;
    }
}
