import {ChangeDetectorRef, Component, DestroyRef, inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {MasterPageModel} from '../../../../../../models/api/master-page.model';
import {DesignLibraryModel} from '../../../../../../modules/static-content-rulesets/models/api/design-library.model';
import {StaticContentRulesetService} from '../../../../../../api/services/static-content-ruleset.service';
import {StaticContentRulesetModel} from '../../../../../../modules/static-content-rulesets/models/api/static-content-ruleset.model';
import {Toaster} from '../../../../../../classes/toaster.class';
import {MasterPagesService} from '../../../../../../api/services/master-pages.service';
import {VariantModel} from '../../../../../../models/api/variant.model';
import {DropdownComponent, EColumnDataType, EColumnSize, ITableColumn} from '@relayter/rubber-duck';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {EChannel} from '../../../../../../app.enums';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ITemplatePresetForm} from '../../static-content-template-preset/static-content-template-preset.component';
import {EEngineType} from '../../../../../../models/api/template.model';
import {DesignLibraryApiService} from '../../../../../../api/services/design-library.api.service';
import {TableSortOptions} from '../../../../../../api/table-sort-options';

export interface ITemplateVariantPresetForm {
    variant: FormControl<VariantModel>;
    masterPage: FormControl<MasterPageModel>;
    designLibrary: FormControl<DesignLibraryModel>;
    ruleset: FormControl<StaticContentRulesetModel>;
}
@Component({
    selector: 'static-content-template-variant-preset',
    templateUrl: 'template-variant-preset.component.html',
    styleUrls: ['template-variant-preset.component.scss']
})
export class TemplateVariantPresetComponent implements OnInit, OnChanges {
    private destroyRef = inject(DestroyRef);
    private changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef)
    public static REQUEST_LIMIT = DropdownComponent.DEFAULT_LIMIT;
    @Input() public formGroup: FormGroup<ITemplatePresetForm | ITemplateVariantPresetForm>;
    @Input() public channel: EChannel;
    @Input() public engineType: EEngineType;
    protected readonly EEngineType = EEngineType;

    public selectedVariant: VariantModel;
    public selectedLibrary: DesignLibraryModel;
    public selectedRuleset: StaticContentRulesetModel;

    public masterPages: MasterPageModel[] = [];
    public masterPageSubscription: Subscription;
    private masterPagePage = 0;
    public totalMasterPages: number;

    public libraries: DesignLibraryModel[] = [];
    public librarySubscription: Subscription;
    private libraryPage = 0;
    public totalLibraries: number;

    public rulesets: StaticContentRulesetModel[] = [];
    public rulesetSubscription: Subscription;
    private rulesetPage = 0;
    public totalFormatRulesets: number;

    public nameColumn: ITableColumn = {
        title: 'Name',
        key: 'name',
        sortProperty: 'name',
        size: EColumnSize.LARGE,
        dataType: EColumnDataType.STRING
    };
    public tableSortOptions = new TableSortOptions([this.nameColumn], 'asc')

    constructor(private masterPageService: MasterPagesService,
                private designLibraryApiService: DesignLibraryApiService,
                private formatRulesetService: StaticContentRulesetService) {
    }

    public ngOnInit(): void {
        // when channel changes, we should get new master pages
        this.initData();

        this.formGroup.controls.designLibrary.valueChanges
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(library => this.onLibraryChanged(library));

        this.formGroup.controls.ruleset.valueChanges
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(ruleSet => this.selectedRuleset = ruleSet);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.channel) {
            this.getMasterPages({reset: true} as IDropdownRequestDataEvent);
        }
        if (changes.engineType) {
            this.getDesignLibraries({reset: true} as IDropdownRequestDataEvent);
        }
    }

    private initData(): void {
        this.selectedVariant = this.formGroup.value['variant']; // variant is not a control in template preset form
        this.selectedLibrary = this.formGroup.value.designLibrary;
        this.selectedRuleset = this.formGroup.value.ruleset;

        if (this.selectedLibrary) {
            if (this.formGroup.controls.ruleset.disabled) this.formGroup.controls.ruleset.enable();
            this.getRulesets();
        } else {
            this.formGroup.controls.ruleset.disable();
        }
    }

    public getMasterPages(event?: IDropdownRequestDataEvent): void {
        if (event?.reset) {
            this.masterPages = [];
            this.masterPagePage = 0;
            this.totalMasterPages = 0;
            this.masterPageSubscription?.unsubscribe();
        }

        // If there is an active subscription, don't respond to data request
        if (this.masterPageSubscription && !this.masterPageSubscription.closed) {
            return;
        }

        this.masterPageSubscription = this.masterPageService.getMasterPages(this.channel, TemplateVariantPresetComponent.REQUEST_LIMIT,
            this.masterPagePage * TemplateVariantPresetComponent.REQUEST_LIMIT, 'name', 'asc', event?.search)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(
                (result) => {
                    this.masterPagePage += 1;
                    this.masterPages = this.masterPages.concat(result.items);
                    this.totalMasterPages = result.total;
                    this.changeDetectorRef.markForCheck();
                });
    }

    public getDesignLibraries(event?: IDropdownRequestDataEvent): void {
        if (event?.reset) {
            this.libraryPage = 0;
            this.libraries = [];
            this.totalLibraries = 0;
            this.librarySubscription?.unsubscribe();
        }

        // If there is an active subscription, don't respond to data request
        if (this.librarySubscription && !this.librarySubscription.closed) {
            return;
        }

        const engineTypes = this.engineType ? [this.engineType] : [];
        this.librarySubscription = this.designLibraryApiService
            .find(
                TemplateVariantPresetComponent.REQUEST_LIMIT,
                this.libraryPage * TemplateVariantPresetComponent.REQUEST_LIMIT,
                this.tableSortOptions,
                undefined,
                event?.search,
                { engineTypes }
            )
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(
                (result) => {
                    this.libraryPage += 1;
                    this.libraries = this.libraries.concat(result.items);
                    this.totalLibraries = result.total;
                    this.changeDetectorRef.markForCheck();
                });
    }

    public getRulesets(event?: IDropdownRequestDataEvent): void {
        if (event?.reset) {
            this.rulesets = [];
            this.rulesetPage = 0;
            this.totalFormatRulesets = 0;
            this.rulesetSubscription?.unsubscribe();
        }

        // If there is an active subscription, don't respond to data request
        if (this.rulesetSubscription && !this.rulesetSubscription.closed) {
            return;
        }

        this.rulesetSubscription = this.formatRulesetService.getStaticContentRulesets(this.selectedLibrary._id,
            TemplateVariantPresetComponent.REQUEST_LIMIT, this.rulesetPage * TemplateVariantPresetComponent.REQUEST_LIMIT,
            null, 'name', 'asc', event?.search)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.rulesetPage += 1;
                    this.rulesets = this.rulesets ? [...this.rulesets, ...result.items] : result.items;
                    this.totalFormatRulesets = result.total;
                    this.changeDetectorRef.markForCheck();
                },
                error: Toaster.handleApiError
            });
    }

    public onLibraryChanged(library: DesignLibraryModel): void {
        this.selectedLibrary = library;
        this.rulesetSubscription?.unsubscribe();

        this.rulesetPage = 0;
        this.rulesets = [];
        this.totalFormatRulesets = 0;

        if (!library || !this.selectedRuleset?.libraries.includes(this.selectedLibrary?._id)) {
            this.formGroup.patchValue({ruleset: null});
        }

        if (this.selectedLibrary) {
            if (this.formGroup.controls.ruleset.disabled) {
                this.formGroup.controls.ruleset.enable({emitEvent: false});
            }
            this.getRulesets();
        } else {
            this.formGroup.controls.ruleset.disable({emitEvent: false});
        }
    }
}
