@import "../../../sass/_variables.scss";
@import "../../../sass/smooth-animated";

// The next style block should be set on the host, but it's not possible at the moment because of ViewEncapsulation
// settings on relayter-container.component.ts. Remove the app-container div and set these styles on the host
// once we fix the ViewEncapsulation issue
.app-container {
    display: grid;
    grid-template-columns: $side-menu-width minmax(0, 1fr);
    min-height: 100vh;
}

rl-menu {
    grid-column: 1;
}

.rl-relayter-main {
    grid-column: 2;
    @extend .smooth-animated;
}

// Material Angular Table overwrite

.mat-sort-header-container {
  align-items: center;
}

.toasters {
    position: fixed;
    z-index: 6;
    right: 0;
    top: 0;
    width: 500px;
}
