import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PackagesService} from '../../../../api/services/packages.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SortDirection} from '@angular/material/sort';
import {PackageModel} from '../../../../models/api/package.model';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {AppConstants} from '../../../../app.constants';
import {Toaster} from '../../../../classes/toaster.class';
import {RLDatePipe} from '../../../../pipes/rl-date.pipe';
import {
    BUTTON_TYPE,
    EColumnDataType,
    EColumnType,
    ESelectionMode,
    ESortOrder,
    IActionClickEvent,
    ITableAction,
    ITableColumn,
    ITableItemPatchModel,
    NucDialogConfigModel,
    NucDialogService,
    TableComponent
} from '@relayter/rubber-duck';
import {PackageItemModel} from '../../../../models/api/package-item.model';
import {Subject, Subscription} from 'rxjs';
import {ISortOptionEvent} from '@relayter/rubber-duck/lib/modules/table/components/table/table-config';
import {ARLogger, ARPagedResponseDataModel} from '@relayter/core';
import {
    PackageSetupCampaignDataFieldModel,
    PackageSetupDataFieldModel
} from '../../../../models/api/package-setup.model';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {EJobStatus, JobModel} from '../../../../models/api/job.model';
import {
    CampaignService,
    ECampaignJobTypes,
    ICampaignUpdatePackageJobData
} from '../../../../api/services/campaigns.service';
import {unlockedBadgeAnimation} from '../../../../animations/unlocked-badge-animation';
import {TableInlineEditingUtil} from '../../../../classes/table-inline-editing.util';
import {MonitoredJobsService} from '../../../../api/services/monitored-updates/monitored-jobs.service';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {takeUntil} from 'rxjs/operators';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {CursorArray} from '../../../../api/api-cursor';
import {PublicationItemModel} from '../../../../models/api/publication-item.model';

@Component({
    selector: 'rl-campaign-package',
    templateUrl: './campaign-package.component.html',
    styleUrls: ['./campaign-package.component.scss'],
    animations: [unlockedBadgeAnimation.unlockBadge],
    providers: [PaginatorService]
})
export class CampaignPackageComponent extends RLTableComponent implements OnInit, OnDestroy {
    @ViewChild(TableComponent) public tableComponent: TableComponent;
    public tableId = 'campaign-package-table';

    private campaignId: string;
    private packageId: string;
    public readonly permissions = AppConstants.PERMISSIONS;
    public selectionMode = ESelectionMode.EXPAND;
    public package: PackageModel;
    public dateFormats = RLDatePipe.dateFormats;

    public columns: ITableColumn[] = [
        {
            title: '',
            selectionColumnTitle: 'Thumbnail',
            key: 'publicationItem.files',
            type: EColumnType.THUMBNAIL,
            // TODO: Still need to make this overview variant aware
            format: (value) => PublicationItemModel.getThumbnailUrl(null, value),
            clickable: true
        },
        {
            title: 'Publication item id',
            key: 'publicationItem.publicationItemId',

        },
    ];
    public allowedActions: ITableAction[];
    public hasNextPage: boolean;
    private apiCursor: CursorArray;
    private sortColumn: ITableColumn;

    public items: PackageItemModel[];
    public subscription: Subscription;

    public pageIndex: number;
    public pageSize: number;
    public sortProperty: string;
    public sortOrder: SortDirection;

    public tableLocked = true;
    public unlockTableTooltipText = 'Unlock table';

    private onDestroySubject = new Subject<void>();

    constructor(private route: ActivatedRoute,
                private router: Router,
                private packageService: PackagesService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private campaignService: CampaignService,
                private monitoredJobsService: MonitoredJobsService,
                private dialogService: NucDialogService,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.setTableActions();
        this.initFromRoute();

        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_PACKAGES)) this.getPackage();
        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination) => {
                if (pagination.pageIndex === 1 || pagination.pageSize !== this.pageSize) {
                    this.apiCursor = new CursorArray(this.pageIndex, this.sortColumn?.sortDuplicates);
                }
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;
                this.updateUrl();
                this.getItems();
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        this.campaignId = this.route.parent.snapshot.params['campaign_id'];
        this.packageId = params['package_id'];
        this.tableId = this.tableId + '-' + this.packageId;
        this.sortProperty = params['sortProperty'] ? params['sortProperty'] : '';
        this.sortOrder = params['sortOrder'] && (params['sortOrder'] === 'asc' || params['sortOrder'] === 'desc') ?
            params['sortOrder'] : '';
    }

    private setTableActions(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.DELETE_PACKAGE_ITEM)) {
            this.allowedActions = [AppConstants.TABLE_ACTION_TYPES.DELETE];
        }
    }

    private getPackage(): void {
        this.packageService.getPackageForCampaign(this.campaignId, this.packageId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (campaignPackage) => {
                    this.package = campaignPackage;
                    const dataFields = campaignPackage.packageSetup.materialDataFields.concat(campaignPackage.packageSetup.campaignDataFields);
                    // we get columns a bit later, how can we link this with our table
                    this.columns = this.columns.concat(this.getDataFieldsColumns(dataFields));
                    this.paginatorService.setPageIndex(this.tableId, 1);
                },
                error: Toaster.handleApiError
            });
    }

    private getItems(): void {
        if (this.subscription) this.subscription.unsubscribe();

        const cursor = this.apiCursor.getCursor(this.pageIndex);
        const offset = cursor._id ? 0 : (this.pageIndex - 1) * this.pageSize;

        this.subscription = this.packageService.getPackageItemsForCampaignPackage(
            this.campaignId, this.packageId, this.pageSize, offset, cursor, this.sortProperty, this.sortOrder)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((result: ARPagedResponseDataModel<PackageItemModel>) => {
                    this.hasNextPage = result.hasNext;
                    this.items = result.items;

                    if (this.items?.length > 0) { // set the cursor
                        const item = this.items[this.items.length - 1];
                        this.apiCursor.setCursor(this.pageIndex, this.sortProperty, item);
                    }
                },
                Toaster.handleApiError);
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        this.sortColumn = event.column?.sortProperty ? event.column : null;
        this.sortProperty = this.sortColumn?.sortProperty || '';
        this.sortOrder = (this.sortColumn?.sortProperty && event.sortOrder === ESortOrder.ASC) ? 'asc' : 'desc';

        this.paginatorService.setPageIndex(this.tableId, 1);
    }

    private getDataFieldsColumns(dataFields: PackageSetupDataFieldModel[]): ITableColumn[] {
        return dataFields.map((dataField) => {
            const column = {
                title: dataField.name,
                key: `dataFields.${dataField.fieldName}`,
                sortProperty: `dataFields.${dataField.fieldName}`,
                sortDuplicates: true
            } as ITableColumn;

            if (!(dataField instanceof PackageSetupCampaignDataFieldModel)) {
                // only material data field can be edited
                column.editable = true;
                column.dataType = dataField.dataType.type as EColumnDataType;
            } else if (dataField.dataType.type === EColumnDataType.DATE) {
                column.dataType = EColumnDataType.DATE;
                column.format = (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.DEFAULT);
            }
            return column;
        });
    }

    private updateUrl(): void {
        const matrixUrl = new MatrixUrlParams(null, null, this.sortProperty, this.sortOrder);
        this.router.navigate([matrixUrl], {replaceUrl: true, relativeTo: this.route});
    }

    public openRefreshConfirmationDialog(): void {
        const dialog = new NucDialogConfigModel('Update campaign package',
            'Are you sure you want to update this campaign package? Only new publication items will be added to the campaign package. ' +
            'Existing package items will not be affected.');
        const confirmDialog = this.dialogService.openDialog(dialog);
        dialog.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => confirmDialog.close());
        dialog.addAction('Update', BUTTON_TYPE.PRIMARY).subscribe(() => {
            confirmDialog.close();
            this.refreshPackage();
        });
    }

    public refreshPackage(): void {
        const jobData = {campaignId: this.campaignId, campaignPackageId: this.packageId} as ICampaignUpdatePackageJobData;
        this.campaignService.postJob(ECampaignJobTypes.CAMPAIGN_UPDATE_PACKAGE_JOB, jobData)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(job => {
                ARLogger.debug('Campaign package update job scheduled: ' + job._id);
                this.monitoredJobsService.getItemMonitor(job._id)
                    .pipe(takeUntil(this.onDestroySubject))
                    .subscribe((jobModel: JobModel) => {
                        if (jobModel.status === EJobStatus.DONE) {
                            this.paginatorService.setPageIndex(this.tableId, 1);
                        }
                    });
            }, error => Toaster.handleApiError(error));
    }

    public onItemEdited(patchObj: ITableItemPatchModel): void {
        const {property, value} = TableInlineEditingUtil.getPropertyAndValue(patchObj);
        const itemToUpdate = this.items.find((item) => item._id === patchObj._id);
        const valueBackup = property.split('.').reduce((o, p) => o && o[p], itemToUpdate);
        // modify front-end object to show user changes
        TableInlineEditingUtil.fillObjectFromPath(property, value, itemToUpdate);
        // create a patchBody containing only the property to update. Example: {dataFields: {some-prop: 'some value'}}
        const updateBody = TableInlineEditingUtil.fillObjectFromPath(property, value);
        this.packageService.patchPackageItem(this.campaignId, this.packageId, patchObj._id, updateBody)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: () => ARLogger.debug('CampaignBriefingComponent: Campaign item edited inline'),
                error: (err) => {
                    // Reset to previous value in case of error
                    TableInlineEditingUtil.fillObjectFromPath(property, valueBackup, itemToUpdate);
                    Toaster.handleApiError(err);
                }
            });
    }

    public handleAction(event: IActionClickEvent): void {
        if (event.action === AppConstants.TABLE_ACTION_TYPES.DELETE) {
            this.openDeletePackageItemDialog(event.item as PackageItemModel);
        }
    }

    private openDeletePackageItemDialog(packageItem: PackageItemModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete package item',
            'Please confirm that you wish to delete this package item.');
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deletePackageItem(packageItem);
        });
    }

    private deletePackageItem(packageItem: PackageItemModel): void {
        this.packageService.deletePackageItem(this.campaignId, this.packageId, packageItem._id)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: success => {
                    Toaster.success(success ? 'Package item successfully deleted' : 'There was an error while deleting the package item');

                    if (success) {
                        if (this.items.length === 1) {
                            // Reset items when deleting last item on the current page
                            this.paginatorService.setPageIndex(this.tableId, 1);
                        } else {
                            // Reload items on the current page
                            this.getItems();
                        }
                    }
                },
                error: error => Toaster.handleApiError(error)
            });
    }
}
