import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
    BUTTON_TYPE,
    DialogButtonConfig,
    DialogCustomContentActionModel,
    DialogCustomContentConfig,
    EColumnSize,
    EColumnType,
    ESelectionMode,
    ESortOrder,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    ISortOptionEvent,
    ITableAction,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogCustomContentService,
    NucDialogService
} from '@relayter/rubber-duck';
import {MasterPageFormComponent} from '../../../../forms/master-page-form/master-page-form.component';
import {AppConstants} from '../../../../app.constants';
import {MasterPagesService} from '../../../../api/services/master-pages.service';
import {ARApiError, ARPagedResponseDataModel} from '@relayter/core';
import {MasterPageModel} from '../../../../models/api/master-page.model';
import {Toaster} from '../../../../classes/toaster.class';
import {SortDirection} from '@angular/material/sort';
import {filter, finalize, take, takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {EJobStatus, JobModel} from '../../../../models/api/job.model';
import {TemplateModel} from '../../../../models/api/template.model';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {MonitoredJobsService} from '../../../../api/services/monitored-updates/monitored-jobs.service';
import {RLDatePipe} from '../../../../pipes/rl-date.pipe';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {ActivatedRoute, Router} from '@angular/router';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {PaginatorService} from '../../../../components/paginator/paginator.service';

@Component({
    selector: 'master-page-overview',
    templateUrl: './master-page-overview.component.html',
    styleUrls: ['./master-page-overview.component.scss'],
    providers: [PaginatorService]
})
export class MasterPageOverviewComponent extends RLTableComponent implements OnInit, OnDestroy {
    public readonly permissions = AppConstants.PERMISSIONS;
    public readonly tableId = 'master-page-overview-table';
    public readonly usageTableId = 'master-page-usage-table';
    private onDestroySubject = new Subject<void>();
    private onDialogCloseSubject = new Subject<void>();

    // overview table related
    public columns: ITableColumn[] = [
        {
            title: '',
            key: 'files.preview.url',
            type: EColumnType.THUMBNAIL,
            format: (value) => value ? value : AppConstants.ICONS.IMAGE_MAIN,
            clickable: true
        },
        {
            title: 'Name',
            key: 'name',
            sortProperty: 'name',
            size: EColumnSize.LARGE
        },
        {
            title: 'Channel',
            key: 'channel',
        },
        {
            title: 'Date modified',
            key: 'updatedAt',
            sortProperty: 'updatedAt',
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.DEFAULT)
        }];

    public actions: ITableAction[];
    public readonly ESelectionMode = ESelectionMode;
    public items: MasterPageModel[];

    public pageIndex = AppConstants.PAGE_INDEX_DEFAULT;
    public pageSize = AppConstants.PAGE_SIZE_DEFAULT;
    public total: number;
    public sortProperty: string;
    public sortOrder: SortDirection;
    public masterPageSubscription: Subscription;

    public deleteButton: DialogButtonConfig;

    // master page usage overview related
    @ViewChild('masterPageUsageOverviewDialog', {static: true}) public masterPageUsageOverviewDialog: TemplateRef<any>;
    public templateColumns: ITableColumn[] = [
        {
            title: 'Affected template(s)',
            key: 'name',
            size: EColumnSize.LARGE,
        }
    ];
    public templates: TemplateModel[] = [];
    public pageIndexTemplates = AppConstants.PAGE_INDEX_DEFAULT;
    public pageSizeTemplates = AppConstants.PAGE_SIZE_DIALOG;
    public pageSizeOptionsTemplates = [new DropdownItem(`${this.pageSizeTemplates}`, this.pageSizeTemplates)];
    public totalTemplates: number;
    public selectedMasterPageId: string;
    public disableNextPage: boolean;
    public disableNextPageTemplates: boolean;
    public masterPagesUsingImageSubscription: Subscription;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private fullModalService: FullModalService,
                private dialogService: NucDialogService,
                private masterPagesService: MasterPagesService,
                private monitoredJobsService: MonitoredJobsService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private dialogCustomContentService: NucDialogCustomContentService,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.setTableActions();
        this.initFromRoute();

        // There is only one page size for master page usage dialog
        this.paginatorService.storePageSize(this.usageTableId, this.pageSizeTemplates);
        // Also, to initiate the paginator service for this tableId
        this.paginatorService.getStoredPageSize(this.usageTableId)
            .pipe(take(1), takeUntil(this.onDestroySubject))
            .subscribe((pageSize: number) => {
                this.pageSizeTemplates = pageSize;
            });

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination) => {
                if (this.pageSize !== pagination.pageSize || this.pageIndex !== pagination.pageIndex) {
                    this.pageIndex = pagination.pageIndex;
                    this.pageSize = pagination.pageSize;
                }

                this.updateUrl();

                this.getMasterPages();
            });

        this.paginatorService.getPagination(this.usageTableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination) => {
                if (this.pageIndexTemplates !== pagination.pageSize || this.pageIndexTemplates !== pagination.pageIndex) {
                    this.pageIndexTemplates = pagination.pageIndex;
                    this.pageSizeTemplates = pagination.pageSize;
                }

                this.getMasterPageUsage();
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDialogCloseSubject.next();
        this.onDestroySubject.complete();
        this.onDialogCloseSubject.complete();
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        const pageIndex = params['pageIndex'] && parseInt(params['pageIndex'], 10) > 0 ? parseInt(params['pageIndex'], 10) : 1;
        this.sortProperty = params['sortProperty'];
        this.sortOrder = params['sortOrder'] && (params['sortOrder'] === 'asc' || params['sortOrder'] === 'desc') ?
            params['sortOrder'] : null;
        this.searchValue = params['search'];

        this.setPageIndex(pageIndex);
    }

    private setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    public updateUrl(): void {
        this.router.navigate([
            AppConstants.CONTEXT_URL.TEMPLATING,
            AppConstants.CONTEXT_URL.MASTER_PAGES,
            new MatrixUrlParams(this.pageIndex, null, this.sortProperty, this.sortOrder, this.searchValue)]);
    }

    public searchValueChanged(): void {
        this.setPageIndex();
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        if (event.column?.sortProperty) {
            this.sortProperty = event.column?.sortProperty;
            this.sortOrder = event.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortProperty = '';
            this.sortOrder = '';
        }

        this.setPageIndex();
    }

    public setTableActions(): void {
        this.actions = [];

        if (this.userIsAllowedToPipe.transform(this.permissions.GET_MASTER_PAGES)) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.DOWNLOAD);
        }
        if (this.userIsAllowedToPipe.transform([this.permissions.PATCH_MASTER_PAGES, this.permissions.GET_MASTER_PAGES_USAGE])) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.EDIT);
        }
        if (this.userIsAllowedToPipe.transform([this.permissions.DELETE_MASTER_PAGE, this.permissions.GET_MASTER_PAGES_USAGE])) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.DELETE);
        }
        if (this.actions.length === 0) this.actions = null;
    }

    public openMasterPageModal(masterPage?: MasterPageModel): void {
        const modalConfig = masterPage
            ? new FullModalConfig('Edit master page',
                'You can edit the information of master page or upload a new file.', {masterPage})
            : new FullModalConfig('Add new master page', 'Add a new master page by uploading it.');
        const modalRef = this.fullModalService.open(MasterPageFormComponent, modalConfig);
        modalRef.afterClosed()
            .pipe(filter((result: Record<string, string>) => !!result))
            .subscribe((result) => !!result.jobId ? this.monitorJob(result.jobId) : this.setPageIndex());
    }

    public getMasterPages(): void {
        if (!this.permissions.GET_MASTER_PAGES) return;
        if (this.masterPageSubscription) this.masterPageSubscription.unsubscribe();

        this.masterPageSubscription =
            this.masterPagesService.getMasterPages(null, this.pageSize,
                (this.pageIndex - 1) * this.pageSize, this.sortProperty, this.sortOrder, this.searchValue)
                .pipe(takeUntil(this.onDestroySubject))
                .subscribe({
                    next: (result: ARPagedResponseDataModel<MasterPageModel>) => {
                        this.items = result.items;
                        this.total = result.total;
                        this.disableNextPage = this.total <= this.pageSize * this.pageIndex;
                    },
                    error: Toaster.handleApiError
                });
    }

    public getMasterPageUsage(): void {
        this.masterPagesUsingImageSubscription = this.masterPagesService.getMasterPageUsage(this.selectedMasterPageId,
            this.pageSizeTemplates, (this.pageIndexTemplates - 1) * this.pageSizeTemplates)
            .pipe(
                finalize(() => this.deleteButton.loading = false),
                takeUntil(this.onDialogCloseSubject))
            .subscribe({
                next: (result: ARPagedResponseDataModel<TemplateModel>) => {
                    this.templates = result.items;
                    this.totalTemplates = result.total;
                    this.disableNextPageTemplates = this.totalTemplates <= this.pageSizeTemplates * this.pageIndexTemplates;
                },
                error: Toaster.handleApiError
            });
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        const item = event.item as MasterPageModel;
        switch (event.action) {
            case AppConstants.TABLE_ACTION_TYPES.DELETE: {
                this.checkUsageAndOpenDeleteDialog(item._id);
                break;
            }
            case AppConstants.TABLE_ACTION_TYPES.EDIT: {
                this.checkUsageAndOpenEditDialog(item._id);
                break;
            }
            case AppConstants.TABLE_ACTION_TYPES.DOWNLOAD: {
                this.masterPagesService.getMasterPage(item._id)
                    .pipe(takeUntil(this.onDestroySubject))
                    .subscribe({
                        next: (masterPage) => {
                            window.open(masterPage.files.template.url);
                        },
                        error: Toaster.handleApiError
                    });
                break;
            }
            default:
                Toaster.notYetImplementedError();
                break;
        }
    }

    private monitorJob(jobId: string): void {
        this.monitoredJobsService.getItemMonitor(jobId)
            .subscribe((jobModel: JobModel) => {
                if (jobModel.status === EJobStatus.DONE) {
                    this.setPageIndex();
                }
            });
    }

    public checkUsageAndOpenEditDialog(masterPageId: string): void {
        this.masterPagesService.getMasterPageUsage(masterPageId, 1, 0)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (result: ARPagedResponseDataModel<TemplateModel>) => {
                    result.total > 0 ? this.openWarningDialog(masterPageId) : this.editMasterPage(masterPageId);
                },
                error: Toaster.handleApiError
            });
    }

    private openWarningDialog(masterPageId: string): void {
        const editDialogConfig = new NucDialogConfigModel('Are you sure?',
            'This master page is currently used in one or more templates in Relayter, editing it may break their layout.');
        const editDialog = this.dialogService.openDialog(editDialogConfig);
        editDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => editDialog.close());
        editDialogConfig.addAction('Confirm', BUTTON_TYPE.PRIMARY).subscribe(() => {
            editDialog.close();
            this.editMasterPage(masterPageId);
        });
    }

    private editMasterPage(masterPageId: string): void {
        this.masterPagesService.getMasterPage(masterPageId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (masterPage) => {
                    this.openMasterPageModal(masterPage);
                },
                error: Toaster.handleApiError
            });
    }

    public checkUsageAndOpenDeleteDialog(masterPageId: string): void {
        this.selectedMasterPageId = masterPageId; // for later query when dialog paging changes
        this.totalTemplates = 0;
        this.templates = [];

        this.openDeleteDialog(masterPageId);

        this.deleteButton.loading = true;
        this.paginatorService.setPageIndex(this.usageTableId, 1);
    }

    public openDeleteDialog(masterPageId: string): void {
        const title = 'Delete master page';
        const description = this.totalTemplates > 0 ?
            `Please confirm that you wish to delete this master page. Templates won't have access to the master page anymore.` :
            `Please confirm that you wish to delete this master page.`;

        const dialogConfig = new DialogCustomContentConfig(title, description);

        const cancelButton = new DialogButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const cancelAction = new DialogCustomContentActionModel(cancelButton);
        dialogConfig.addAction(cancelAction);

        this.deleteButton = new DialogButtonConfig(BUTTON_TYPE.DESTRUCTIVE, 'Delete');
        const deleteAction = new DialogCustomContentActionModel(this.deleteButton);
        dialogConfig.addAction(deleteAction);

        cancelAction.observable.subscribe(() => {
            this.dialogCustomContentService.close();
        });
        deleteAction.observable.subscribe(() => {
            this.deleteButton.loading = true;
            this.deleteMasterPage(masterPageId);
        });

        this.dialogCustomContentService
            .open(this.masterPageUsageOverviewDialog, dialogConfig)
            .afterClosed()
            .subscribe(() => {
                this.onDialogCloseSubject.next();
                this.resetDialogData();
            });
    }

    public deleteMasterPage(masterPageId: string): void {
        this.masterPagesService.deleteMasterPage(masterPageId)
            .pipe(finalize(() => this.deleteButton.loading = false), takeUntil(this.onDestroySubject))
            .subscribe({
                next: (res: boolean) => {
                    res ? Toaster.success('Master page successfully deleted') : Toaster.error('There was an error while deleting the master page');
                    this.setPageIndex();
                    this.dialogCustomContentService.close();
                },
                error: (error: ARApiError) => {
                    Toaster.handleApiError(error);
                    this.dialogCustomContentService.close();
                }
            });
    }

    public resetDialogData(): void {
        this.pageIndexTemplates = 1;
        this.templates = [];
        this.selectedMasterPageId = null;
        this.totalTemplates = null;
    }
}
