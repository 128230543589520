import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

/**
 * Checks if object contains values for given keys and returns required or not
 * @param {string[]} requiredKeys - array of required properties
 * @returns {object | null}
 * @constructor
 */
export function ObjectPropertyRequiredValidator(requiredKeys: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value || typeof control.value !== 'object') {
            return { required: true }; // Value is not an object or is null/undefined
        }

        // Check if all required keys are present and have non-empty values
        const isValid = requiredKeys.every(key =>
            control.value[key] !== null && control.value[key] !== undefined && control.value[key] !== ''
        );
        return isValid ? null : { required: true };
    };
}
