<div class="oauth-client-details-header">
    <h1>OAuth client details</h1>
    @if (permissions.PATCH_OAUTH_CLIENT | userIsAllowedTo) {
        <nuc-button-secondary
            (click)="editConnection()"
            [disabled]="loading"
            text="Edit">
        </nuc-button-secondary>
    }
</div>
@if (oauthClient()) {
    <div class="oauth-client-details">
        <div class="left">
            <img class="logo" [src]="logo">
        </div>
        <div class="properties-container">
            <div class="properties">
                <div class="field">
                    <p class="body-strong">Name</p>
                    <p>{{ oauthClient().name }}</p>
                </div>
                <div class="field">
                    <p class="body-strong">Grant types</p>
                    <p>{{ oauthClient().grant_types.join(', ') }}</p>
                </div>
                <div class="field">
                    <p class="body-strong">Client Id</p>
                    <key-viewer [key]="oauthClient().client_id" [disabled]="loading">
                    </key-viewer>
                </div>
                <div class="field">
                    <p class="body-strong">Client Secret</p>
                    <key-viewer [key]="oauthClient().client_secret" [disabled]="loading">
                    </key-viewer>
                </div>
            </div>
        </div>
    </div>
}

