import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppConstants} from '../../../../app.constants';
import {
    IWorkflowAutomationFormData,
    WorkflowAutomationFormComponent
} from '../../../../forms/workflow-automation-form/workflow-automation-form.component';
import {ActivatedRoute, Router} from '@angular/router';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../../../classes/toaster.class';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {EWorkflowAutomationJobType, WorkflowAutomationsService} from '../../../../api/services/workflow-automations.service';
import {WorkflowAutomationModel} from '../../../../models/api/workflow-automation.model';
import {Subject, Subscription} from 'rxjs';
import {
    BUTTON_TYPE,
    EColumnSize,
    EColumnType,
    ESelectionMode,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    IItemClickEvent,
    ISortOptionEvent,
    ITableAction,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogService,
} from '@relayter/rubber-duck';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {takeUntil} from 'rxjs/operators';
import {EJobStatus, JobModel} from '../../../../models/api/job.model';
import {MonitoredJobsService} from '../../../../api/services/monitored-updates/monitored-jobs.service';

@Component({
    selector: 'workflow-automation-overview',
    templateUrl: './workflow-automation-overview.component.html',
    styleUrls: ['./workflow-automation-overview.component.scss'],
    providers: [PaginatorService]
})
export class WorkflowAutomationOverviewComponent extends RLTableComponent implements OnInit, OnDestroy {
    public readonly tableId = 'workflow-automation-overview-table';

    public columns: ITableColumn[] = [{
        title: 'Name',
        key: 'name',
        type: EColumnType.DEFAULT,
        sortProperty: 'name',
        size: EColumnSize.LARGE
    }, {
        title: 'Description',
        key: 'description',
        size: EColumnSize.LARGE
    }];
    public actions: ITableAction[];

    public ESelectionMode = ESelectionMode;
    public hasNext: boolean;
    public items: WorkflowAutomationModel[];

    public pageIndex: number;
    public pageSize: number;

    public subscription: Subscription;
    private onDestroySubject = new Subject<void>();

    constructor(private fullModalService: FullModalService,
                private router: Router,
                private route: ActivatedRoute,
                private dialogService: NucDialogService,
                private workflowAutomationsService: WorkflowAutomationsService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private paginatorService: PaginatorService,
                private monitoredJobsService: MonitoredJobsService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.initFromRoute();
        this.setTableActions();
        this.paginatorService.getPagination(this.tableId).subscribe((result: { pageIndex: number; pageSize: number }) => {
            if (result.pageIndex === 1 || result.pageSize !== this.pageSize) { // reset cursor when needed
                this.newApiCursor.reset(result.pageIndex, this.tableSortOptions);
            }
            this.pageIndex = result.pageIndex;
            this.pageSize = result.pageSize;
            this.router.navigate([AppConstants.CONTEXT_URL.WORKFLOWS, AppConstants.CONTEXT_URL.WORKFLOW_AUTOMATIONS, this.createMatrixUrl()]);
            this.getWorkflowAutomations();
        });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : 1;

        this.tableSortOptions.fromRoute(params, this.columns);

        this.setPageIndex(pageIndex);
    }

    private setPageIndex(pageIndex = 1): void {
        this.newApiCursor.reset(pageIndex);
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    private setTableActions(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.PATCH_WORKFLOW_AUTOMATION)) {
            this.actions = [AppConstants.TABLE_ACTION_TYPES.EDIT];
        }
        if (this.userIsAllowedToPipe.transform([AppConstants.PERMISSIONS.POST_WORKFLOW_AUTOMATION_JOB,
            AppConstants.PERMISSIONS.COPY_WORKFLOW_AUTOMATION])) {
            this.actions = this.actions ? this.actions.concat(AppConstants.TABLE_ACTION_TYPES.COPY) : [AppConstants.TABLE_ACTION_TYPES.COPY];
        }
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.DELETE_WORKFLOW_AUTOMATION)) {
            this.actions = this.actions ? this.actions.concat(AppConstants.TABLE_ACTION_TYPES.DELETE) : [AppConstants.TABLE_ACTION_TYPES.DELETE];
        }
    }

    public getWorkflowAutomations(): void {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_WORKFLOW_AUTOMATIONS)) return;
        if (this.subscription) this.subscription.unsubscribe();

        // If we don't have a cursor value (if we reload the page for example), fall back to offset
        const cursor = this.newApiCursor.getCursor(this.pageIndex);
        const offset = (this.pageIndex === 1 || cursor._id) ? 0 : (this.pageIndex - 1) * this.pageSize;

        this.subscription = this.workflowAutomationsService.getWorkflowAutomations(
            this.pageSize,
            offset,
            cursor,
            this.tableSortOptions,
        ).pipe(takeUntil(this.onDestroySubject)).subscribe({
            next: (result: ARPagedResponseDataModel<WorkflowAutomationModel>) => {
                ({hasNext: this.hasNext, items: this.items} = result);
                if (this.items.length > 0) {
                    const item = this.items[this.items.length - 1];
                    this.newApiCursor.setCursor(this.pageIndex, item);
                }
            },
            error: Toaster.handleApiError
        });
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        const workflowAutomation = event.item as WorkflowAutomationModel;
        if (event.action === AppConstants.TABLE_ACTION_TYPES.EDIT) {
            this.workflowAutomationsService.findOne(workflowAutomation._id)
                .pipe(takeUntil(this.onDestroySubject))
                .subscribe({
                    next: (automation: WorkflowAutomationModel) => this.openWorkflowAutomationModal(automation),
                    error: Toaster.handleApiError
                });
        }
        if (event.action === AppConstants.TABLE_ACTION_TYPES.COPY) {
            this.openCopyDialog(workflowAutomation);
        }
        if (event.action === AppConstants.TABLE_ACTION_TYPES.DELETE) {
            this.openDeleteDialog(workflowAutomation);
        }
    }

    private createMatrixUrl(): MatrixUrlParams {
        return  new MatrixUrlParams(this.pageIndex, null, this.tableSortOptions.sortPropertiesAsString, this.tableSortOptions.sortOrder);
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        this.tableSortOptions.updateWithSortOptionEvent(sortEvent);

        this.setPageIndex();
    }

    public onAddClicked(): void {
        this.openWorkflowAutomationModal();
    }

    private openWorkflowAutomationModal(workflowAutomation?: WorkflowAutomationModel): void {
        const modalConfig = workflowAutomation
            ? new FullModalConfig('Edit automation',
                'Edit the information of the workflow automation.', {workflowAutomation} as IWorkflowAutomationFormData)
            : new FullModalConfig('Add automation', 'Enter the information to create a new workflow automation.', {});
        modalConfig.confirmClose = true;
        const modalRef = this.fullModalService.open(WorkflowAutomationFormComponent, modalConfig);
        modalRef.afterClosed().subscribe((result) => {
            if (result) this.setPageIndex();
        });
    }

    private openCopyDialog(workflowAutomation: WorkflowAutomationModel): void {
        const copyDialogConfig = new NucDialogConfigModel(`Duplicate ${workflowAutomation.name}`,
            'You are about to duplicate an automation workflow with all the content. Are you sure?');
        const copyDialog = this.dialogService.openDialog(copyDialogConfig);
        copyDialogConfig.addAction('No', BUTTON_TYPE.SECONDARY).subscribe(() => copyDialog.close());
        copyDialogConfig.addAction('Yes', BUTTON_TYPE.PRIMARY).subscribe(() => {
            copyDialog.close();
            this.copyWorkflowAutomation(workflowAutomation._id);
        });
    }

    private copyWorkflowAutomation(workflowAutomationId: string): void {
        const jobData = {workflowAutomationId};

        this.subscription = this.workflowAutomationsService.postJob(EWorkflowAutomationJobType.WORKFLOW_AUTOMATION_COPY_JOB, jobData)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (job: JobModel) => this.monitoredJobsService.getItemMonitor(job._id)
                    .pipe(takeUntil(this.onDestroySubject))
                    .subscribe((jobModel: JobModel) => {
                        if (jobModel.status === EJobStatus.DONE) {
                            this.getWorkflowAutomations();
                        }
                    }),
                error: Toaster.handleApiError
            });
    }

    private openDeleteDialog(workflowAutomation: WorkflowAutomationModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete automation',
            `Please confirm that you wish to delete ${workflowAutomation.name}.`);
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deleteWorkflowAutomation(workflowAutomation);
        });
    }

    private deleteWorkflowAutomation(workflowAutomation: WorkflowAutomationModel): void {
        this.workflowAutomationsService.deleteOne(workflowAutomation._id)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: () => {
                    Toaster.success('Workflow automation removed successfully');
                    this.paginatorService.setPageIndex(this.tableId, 1);
                },
                error: Toaster.handleApiError
            });
    }

    public onItemClicked(event: IItemClickEvent): void {
        this.router.navigate([AppConstants.CONTEXT_URL.WORKFLOWS, AppConstants.CONTEXT_URL.WORKFLOW_AUTOMATIONS, event.item._id]);
    }
}
