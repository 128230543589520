import {Component, Inject, OnInit} from '@angular/core';
import {CampaignModel} from '../../../../models/api/campaign.model';
import {
    CampaignService,
    ECampaignJobTypes,
    ICopyCampaignJobData,
    IDeleteCampaignJobData
} from '../../../../api/services/campaigns.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ARLogger} from '@relayter/core';
import {Toaster} from '../../../../classes/toaster.class';
import {AppConstants} from '../../../../app.constants';
import {TemporaryStorageUtil} from '../../../../classes/rl-temporary-storage.util';
import {CampaignFormComponent, ICampaignFormData} from '../../../../forms/campaign-form/campaign-form.component';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {
    BUTTON_TYPE,
    EColumnDataType,
    EColumnSize,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    IItemClickEvent,
    ITableAction,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {EJobStatus, JobModel} from '../../../../models/api/job.model';
import {combineLatest} from 'rxjs';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {FormControl, FormGroup} from '@angular/forms';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {
    ESegmentTrackEvents,
    ISegmentService,
    SEGMENT_SERVICE
} from '../../../../services/segment/segment.service.interface';
import {MonitoredJobsService} from '../../../../api/services/monitored-updates/monitored-jobs.service';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {ELastViewedItemsContext, LastViewedItemsService} from '../../../../api/services/last-viewed-items.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CampaignDataProvider} from './campaign.data-provider';
import {RLDatePipe} from '../../../../pipes/rl-date.pipe';
import {TitleCasePipe} from '@angular/common';

@Component({
    selector: 'rl-campaigns-overview',
    templateUrl: 'campaigns-overview.component.html',
    styleUrls: ['campaigns-overview.component.scss'],
    providers: [PaginatorService]
})

export class CampaignsOverviewComponent extends RLTableComponent implements OnInit {

    public readonly context = ELastViewedItemsContext.CAMPAIGN;
    public readonly tableId = 'campaign-overview-table';
    public items: CampaignModel[] = [];

    public columns: ITableColumn[] = [
        {
            title: 'Name',
            key: 'name',
            size: EColumnSize.LARGE
        },
        {
            title: 'Status',
            key: 'status',
            format: (value) => this.titleCasePipe.transform(value)
        },
        {
            title: 'Date',
            key: 'startEndDate',
            size: EColumnSize.BASE
        },
        {
            title: 'Tags',
            key: 'tags',
            format: (value) => value?.join(', ')
        },
        {
            title: 'Variants',
            key: 'variants',
            format: (value) => value?.map(val => val.name).join(', ')
        },
        {
            title: 'Date created',
            key: 'createdAt',
            size: EColumnSize.SMALL,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        }, {
            title: 'Date modified',
            key: 'updatedAt',
            size: EColumnSize.SMALL,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        }
    ];

    public actions: ITableAction[] = [];
    public applyLastViewedItemIds: boolean; // used to disable sorting, even though this table cannot be sorted yet
    public lastViewedItemIds: string[];

    public statuses: DropdownItem<string>[] = Object.values(AppConstants.CAMPAIGN_STATUS)
        .map((status) => new DropdownItem<string>(this.titleCasePipe.transform(status), status));

    public formGroup: FormGroup<{status: FormControl<DropdownItem<string>>}>;

    public campaignFilterData: string;

    public campaignDataProvider = new CampaignDataProvider(this.tableSortOptions);
    private defaultSortColumns = [{
        key: 'startDate',
        sortProperty: 'startDate',
        sortDuplicates: true,
        dataType: EColumnDataType.DATE
    }, {
        key: 'endDate',
        sortProperty: 'endDate',
        sortDuplicates: true,
        dataType: EColumnDataType.DATE
    }] as ITableColumn[];

    constructor(private router: Router,
                private route: ActivatedRoute,
                private titleCasePipe: TitleCasePipe,
                private campaignService: CampaignService,
                private sessionStorage: TemporaryStorageUtil,
                private dialogService: NucDialogService,
                private fullModalService: FullModalService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private monitoredJobsService: MonitoredJobsService,
                @Inject(SEGMENT_SERVICE) private segmentService: ISegmentService,
                private paginatorService: PaginatorService,
                private lastViewedItemsService: LastViewedItemsService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.campaignDataProvider.sortOptions.columns.push(...this.defaultSortColumns);
        this.campaignDataProvider.sortOptions.sortOrder = 'desc';

        this.initFromRoute();
        this.listenToPanelControl(); // pagination and lastViewedItemsCheckbox
        this.initActions();
        this.initForm();
    }

    private initActions(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.PUT_CAMPAIGN)) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.EDIT);
        }
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.COPY_CAMPAIGN)) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.COPY);
        }
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.DELETE_CAMPAIGN)) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.DELETE);
        }
    }

    private listenToPanelControl(): void {
        // we only want to have one place to get campaigns
        combineLatest([
            this.paginatorService.getPagination(this.tableId),
            this.lastViewedItemsService.getState(this.context).pipe(
                filter((res) => {
                    this.lastViewedItemIds = res.itemIds;
                    if (res.applied && !this.applyLastViewedItemIds && this.campaignDataProvider.pageIndex !== 1) {
                        this.applyLastViewedItemIds = res.applied;
                        this.paginatorService.setPageIndex(this.tableId, 1);
                        return false; // filter out this state emission to avoid duplicate api call
                    }
                    this.applyLastViewedItemIds = res.applied;
                    return true;
                })
            )
        ]).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(([pagination]) => {
            if (pagination.pageIndex === 1 || pagination.pageSize !== this.campaignDataProvider.pageSize) {
                this.campaignDataProvider.resetCursorArray(pagination.pageIndex);
            }

            this.campaignDataProvider.pageIndex = pagination.pageIndex;
            this.campaignDataProvider.pageSize = pagination.pageSize;

            this.router.navigate([AppConstants.CONTEXT_URL.CAMPAIGNS, this.createMatrixUrl()]);

            this.getCampaigns();
        });
    }

    public initForm(): void {
        this.campaignFilterData = this.sessionStorage.getSelectedCampaignStatus();
        const selectedStatus = this.statuses.find((status) => status.getValue() === this.campaignFilterData);
        this.formGroup = new FormGroup({
            status: new FormControl(selectedStatus)
        });

        this.formGroup.valueChanges.pipe(
            map(({status}) => status?.getValue()),
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((status) => {
            this.campaignFilterData = status;
            this.sessionStorage.storeSelectedCampaignStatus(this.campaignFilterData);
            this.paginatorService.setPageIndex(this.tableId, 1);
        });
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        this.campaignDataProvider.searchValue = params['search'];
        const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : 1;
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
        // we don't set pageSize from route
    }

    /**
     * On table view row clicked navigate to campaign page
     */
    public onTableRowClicked(event: IItemClickEvent): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_CAMPAIGN)) {
            this.router.navigate([AppConstants.CAMPAIGN_PATH, event.item._id], {skipLocationChange: false});
        }
    }

    /**
     * Get campaigns
     */
    private getCampaigns(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_CAMPAIGNS)) {
            this.campaignDataProvider.filterValues = {};
            this.campaignDataProvider.filterValues.status = this.campaignFilterData;

            if (this.applyLastViewedItemIds) this.campaignDataProvider.filterValues._id = this.lastViewedItemIds;

            this.campaignDataProvider.retrieveData();
        }
    }

    public handleTableAction(event: IActionClickEvent): void {
        const clickedCampaign = event.item as CampaignModel;
        switch (event.action) {
            case AppConstants.TABLE_ACTION_TYPES.EDIT:
                this.openCampaignForm(clickedCampaign);
                break;
            case AppConstants.TABLE_ACTION_TYPES.COPY:
                this.openCopyDialog(clickedCampaign);
                break;
            case AppConstants.TABLE_ACTION_TYPES.DELETE:
                this.openDeleteDialog(clickedCampaign);
                break;
        }
    }

    public openCampaignForm(campaign?: CampaignModel): void {
        const data = {campaign} as ICampaignFormData;
        const config = new FullModalConfig(
            campaign ?
                'Edit campaign' :
                'Add new campaign',
            campaign ?
                'Update the campaign information.' :
                'Fill in general information about the campaign.',
            data);
        config.confirmClose = true;
        this.fullModalService.open(CampaignFormComponent, config).afterClosed().subscribe((result) => {
            if (result) {
                !campaign && this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.GET_CAMPAIGN) ?
                    this.router.navigate([AppConstants.CAMPAIGN_PATH, result._id]) :
                    this.getCampaigns();
            }
        });
    }

    public openCopyDialog(campaign: CampaignModel): void {
        const copyDialogConfig = new NucDialogConfigModel(`Copy Campaign ${campaign.name}`,
            'You are about to copy a campaign with all briefing information. Publications are not copied. Are you sure?');
        const copyDialog = this.dialogService.openDialog(copyDialogConfig);
        copyDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => {
            copyDialog.close();
        });
        copyDialogConfig.addAction('Copy', BUTTON_TYPE.PRIMARY).subscribe(() => {
            copyDialog.close();
            this.copyCampaign(campaign);
        });
    }

    private openDeleteDialog(campaign: CampaignModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete campaign',
            `Please confirm that you wish to delete ${campaign.name}.`);
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => {
            deleteDialog.close();
        });
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deleteCampaign(campaign);
        });
    }

    /**
     * Create a MatrixUrlModel so the url always has the correct amount of parameters
     * @return {MatrixUrlParams}
     */
    public createMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(this.campaignDataProvider.pageIndex, null, null, null, this.campaignDataProvider.searchValue || '');
    }

    public onSearchBarValueUpdated(): void {
        this.paginatorService.setPageIndex(this.tableId, 1);
    }

    /**
     * Deletes a campaign
     */
    public deleteCampaign(campaign: CampaignModel): void {
        const jobData = {
            campaignId: campaign._id
        } as IDeleteCampaignJobData;

        this.campaignService.postJob(ECampaignJobTypes.CAMPAIGN_DELETE_JOB, jobData).subscribe({
            next: (scheduleJob: JobModel) => {
                ARLogger.debug('Job scheduled: ' + scheduleJob._id);
                this.monitoredJobsService.getItemMonitor(scheduleJob._id)
                    .subscribe((jobModel: JobModel) => {
                        if (jobModel.status === EJobStatus.DONE) {
                            this.segmentService.track(ESegmentTrackEvents.TRACK_REMOVE_CAMPAIGN);
                            // we check if the campaign it's the only item on the last page, if so, we navigate back one page
                            if (this.campaignDataProvider.disableNextPage && this.campaignDataProvider.pageIndex > 1 &&
                                this.items[this.items.length - 1]._id === campaign._id) {
                                this.paginatorService.setPageIndex(this.tableId, this.campaignDataProvider.pageIndex - 1);
                            } else {
                                this.getCampaigns();
                            }
                            this.router.navigate([AppConstants.CAMPAIGN_PATH]);
                        }
                    });
            },
            error: Toaster.handleApiError
        });
    }

    /**
     * Copies a campaign
     */
    public copyCampaign(campaign: CampaignModel): void {
        const jobData = {
            campaignId: campaign._id
        } as ICopyCampaignJobData;

        this.campaignService.postJob(ECampaignJobTypes.CAMPAIGN_COPY_JOB, jobData).subscribe({
            next: (scheduleJob: JobModel) => {
                ARLogger.debug('Job scheduled: ' + scheduleJob._id);
                this.monitoredJobsService.getItemMonitor(scheduleJob._id)
                    .subscribe((jobModel: JobModel) => {
                        if (jobModel.status === EJobStatus.DONE) {
                            this.getCampaigns();
                        }
                    });
            },
            error: Toaster.handleApiError
        });
    }
}
