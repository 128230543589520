export const defaultCampaignItemScript = `/**
 * Define your transform script function here. You can add, update and delete dataField and campaign properties.
 * You can use properties from the original body and assign to a dataField or campaign property.
 * You can use dayjs and dayjs timezone for date functions.
 * The returned dataFields and campaign will be stored
 */
function _transform (body, dataFields, campaign) {

  return { dataFields, campaign };
}`
export const defaultProductScript = `/**
 * Define your script function here. You can add, update and delete mapped dataField properties.
 * You can use properties from the original body and assign to a dataField.
 * You can use dayjs and dayjs timezone for date functions.
 * The returned dataFields will be stored.
 */
function _transform (body, dataFields) {

  return { dataFields };
}`

export const defaultAssetScript = `/**
 * Define your script function here. You can add, update and delete mapped dataField properties.
 * You can use properties from the original body and assign to a dataField.
 * You can use dayjs and dayjs timezone for date functions.
 * The returned dataFields will be stored.
 */
function _transform (body, dataFields) {

  return { dataFields };
}`
