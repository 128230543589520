// Modules
import {NgModule} from '@angular/core';
import {RelayterRouterModule} from './routes/relayter-router.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatRippleModule} from '@angular/material/core';
import {NgChartsModule} from 'ng2-charts';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';

// Pages
import {RelayterContainerComponent} from './relayter-container.component';
import {ProductsOverviewComponent} from './products/overview/products-overview.component';
import {UserManagementComponent} from './user-management/user-management.component';
import {RolesOverviewComponent} from './roles/roles-overview.component';
import {RoleDetailContainerComponent} from './role-detail/role-detail-container.component';
import {RolePermissionsComponent} from './role-detail/role-permissions/role-permissions.component';
import {RoleUsersComponent} from './role-detail/role-users/role-users.component';
import {UserDetailComponent} from './user-management/users-overview/detail/user-detail.component';
import {ProductDetailComponent} from './products/detail/products-detail.component';
import {CampaignsOverviewComponent} from './campaigns/overview/campaigns-overview.component';
import {ComponentsModule} from '../../components/components.module';
import {AccountComponent} from './account/account.component';
import {CampaignsDetailComponent} from './campaigns/detail/campaigns-detail.component';
import {CampaignsBriefingComponent} from './campaigns/briefing/campaigns-briefing.component';
import {TeamSettingsComponent} from './team-settings/team-settings.component';
import {AssetOverviewComponent} from './assets/overview/asset-overview.component';
import {StaticContentTemplateOverviewComponent} from './templates/overview/static-content-template-overview.component';
import {LibraryOverviewComponent} from './library-items/overview/library-overview.component';
import {LinkAssetsToProductsViewComponent} from './assets/link-assets-to-products-view/link-assets-to-products-view.component';
import {AssetDetailComponent} from './assets/detail/asset-detail.component';
import {MediaCardViewComponent, NUCButtonBarModule, RDModule, SegmentedStatusBarComponent, VideoPlayerComponent} from '@relayter/rubber-duck';
import {GeneralInformationComponent} from './team-settings/general-information/general-information.component';
import {MasterPageOverviewComponent} from './master-pages/master-page-overview/master-page-overview.component';
import {MenuModule} from '../../menu/menu.module';
import {GroupsModule} from './groups/groups.module';
import {ContentRulesModule} from './content-rules/content-rules.module';
import {BriefingDataManagementComponent} from './briefing-data-management/briefing-data-management.component';
import {ProductDataManagementComponent} from './product-data-management/product-data-management.component';
import {PendingInvitesComponent} from './user-management/pending-invites/pending-invites.component';
import {UsersOverviewComponent} from './user-management/users-overview/users-overview.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {FormOverviewComponent} from './data-management/form-overview/form-overview.component';
import {GetDataFieldByDisplayNamePipe} from '../../pipes/data-fields/get-data-field-value-by-display-name.pipe';
import {PackageSetupOverviewComponent} from './packages/package-setup-overview/package-setup-overview.component';
import {PackageSetupDetailsComponent} from './packages/package-setup-details/package-setup-details.component';
import {CampaignPackageComponent} from './campaigns/campaign-package/campaign-package.component';
import {WorkflowAutomationOverviewComponent} from './workflow-automations/workflow-automation-overview/workflow-automation-overview.component';
import {WorkflowAutomationDetailsComponent} from './workflow-automations/workflow-automation-details/workflow-automation-details.component';
import {AssetDataManagementComponent} from './asset-data-management/asset-data-management.component';
import {ConnectionsComponent} from './team-settings/connections/connections.component';
import {VariantsOverviewComponent} from './variants/variants-overview.component';
import {VariantFormComponent} from './variants/variant-form/variant-form.component';
import {ConnectionDetailMediavaletComponent} from './team-settings/connections/details/media-valet/connection-detail-mediavalet.component';
import {TeamSettingsOverviewComponent} from './team-settings/team-settings-overview/team-settings-overview.component';
import {StaticContentRulesetsModule} from '../../modules/static-content-rulesets/static-content-rulesets.module';
import {PipesModule} from '../../pipes/pipes.module';
import {TemplateDetailsModule} from './templates/static-content-template-form/static-content-template-form.module';
import {CampaignDetailBaseComponent} from './campaigns/campaign-detail-base/campaign-detail-base.component';
import {NgxFilesizeModule} from 'ngx-filesize';
import {WorkflowConfigurationOverviewComponent} from './workflow-configurations/overview/workflow-configuration-overview.component';
import {FormComponentsModule} from '../../forms/form-components.module';
import {CustomWorkflowModule} from './campaigns/publication/custom-workflow/custom-workflow.module';
import {CampaignPublicationsOverviewComponent} from '../../components/campaign-publications/campaign-publications-overview.component';
import {CampaignPackagesOverviewComponent} from '../../components/campaign-packages/campaign-packages-overview.component';
import {
    ConnectionDetailWebhookConsumerComponent
} from './team-settings/connections/details/webhook-consumer/connection-detail-webhook-consumer.component';
import {ConnectionDetailSuperunieAdamComponent} from './team-settings/connections/details/superunie-adam/connection-detail-superunie-adam.component';
import {CommonModule} from '@angular/common';
import {WorkflowConfigurationDetailsComponent} from './workflow-configurations/details/workflow-configuration-details.component';
import {
    WorkflowConfigurationPropertiesComponent
} from './workflow-configurations/details/workflow-configuration-properties/workflow-configuration-properties.component';
import {
    WorkflowConfigurationComponentDetailsComponent
} from './workflow-configurations/details/workflow-configuration-component-details.component.ts/workflow-configuration-component-details.component';
import {
    WorkflowConfigurationStepDetailsComponent
} from './workflow-configurations/details/workflow-configuration-step-details/workflow-configuration-step-details.component';
import {
    WorkflowConfigurationDetailPropertyComponent
} from './workflow-configurations/details/workflow-configuration-detail-property/workflow-configuration-detail-property.component';
import {
    ProductAssetExportSetupOverviewComponent
} from './product-asset-export-setups/product-asset-export-setup-overview/product-asset-export-setup-overview.component';
import {AuditTrailOverviewComponent} from './audit-trail/audit-trail-overview.component';
import {
    StaticContentTemplateInUseDialogComponent
} from './templates/overview/static-content-template-in-use/static-content-template-in-use-dialog.component';
import {FontOverviewComponent} from './fonts/overview/font-overview.component';
import {NotificationPreferenceComponent} from './account/preferences/notifications/notification-preference.component';
import {DataFilterComponent} from '../../components/data-filter/data-filter.component';
import {JobToasterComponent} from '../../components/toasters/job-toaster/job-toaster.component';
import {TransitionToasterComponent} from '../../components/toasters/transition-toaster/transition-toaster.component';

const components = [
    GetDataFieldByDisplayNamePipe,
    RelayterContainerComponent,
    ProductsOverviewComponent,
    AccountComponent,
    UserManagementComponent,
    RolesOverviewComponent,
    RoleDetailContainerComponent,
    RolePermissionsComponent,
    RoleUsersComponent,
    UserDetailComponent,
    ProductDetailComponent,
    CampaignsOverviewComponent,
    CampaignsDetailComponent,
    CampaignsBriefingComponent,
    AssetOverviewComponent,
    AssetDetailComponent,
    TeamSettingsComponent,
    StaticContentTemplateOverviewComponent,
    StaticContentTemplateInUseDialogComponent,
    LibraryOverviewComponent,
    LinkAssetsToProductsViewComponent,
    GeneralInformationComponent,
    MasterPageOverviewComponent,
    BriefingDataManagementComponent,
    ProductDataManagementComponent,
    FormOverviewComponent,
    PendingInvitesComponent,
    UsersOverviewComponent,
    PackageSetupOverviewComponent,
    PackageSetupDetailsComponent,
    CampaignPackageComponent,
    WorkflowAutomationOverviewComponent,
    WorkflowAutomationDetailsComponent,
    AssetDataManagementComponent,
    ConnectionsComponent,
    VariantsOverviewComponent,
    VariantFormComponent,
    ConnectionDetailMediavaletComponent,
    TeamSettingsOverviewComponent,
    CampaignDetailBaseComponent,
    WorkflowConfigurationOverviewComponent,
    CampaignPublicationsOverviewComponent,
    CampaignPackagesOverviewComponent,
    ConnectionDetailWebhookConsumerComponent,
    WorkflowConfigurationDetailsComponent,
    WorkflowConfigurationStepDetailsComponent,
    WorkflowConfigurationComponentDetailsComponent,
    WorkflowConfigurationPropertiesComponent,
    WorkflowConfigurationDetailPropertyComponent,
    ProductAssetExportSetupOverviewComponent,
    AuditTrailOverviewComponent,
    FontOverviewComponent
];

@NgModule({
    imports: [
        RelayterRouterModule,
        CommonModule,
        ComponentsModule,
        FormComponentsModule,
        CustomWorkflowModule,
        TemplateDetailsModule,
        MatProgressBarModule,
        FormsModule,
        ReactiveFormsModule,
        MatPaginatorModule,
        MatMenuModule,
        RDModule,
        MenuModule,
        MatRippleModule,
        GroupsModule,
        ContentRulesModule,
        ClipboardModule,
        NgChartsModule,
        StaticContentRulesetsModule,
        NUCButtonBarModule,
        PipesModule,
        NgxFilesizeModule,
        VideoPlayerComponent,
        MediaCardViewComponent,
        ConnectionDetailSuperunieAdamComponent,
        SegmentedStatusBarComponent,
        NotificationPreferenceComponent,
        DataFilterComponent,
        JobToasterComponent,
        TransitionToasterComponent
    ],
    providers: [],
    declarations: components,
    exports: components
})

export class RelayterModule {
}
