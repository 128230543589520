<div [formGroup]="formGroup()" class="preference">
    <div>
        <div class="body-strong">{{NotificationTypesConfig[formGroup().controls.type.value].title}}</div>
        <div>{{NotificationTypesConfig[formGroup().controls.type.value].description}}</div>
    </div>
    <nuc-form-field>
        <nuc-dropdown-multiselect
            formControlName="options"
            placeholder="None"
            [data]="formGroup().controls.options.value"
            [items]="options"/>
    </nuc-form-field>
</div>
