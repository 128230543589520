import {Pipe, PipeTransform} from '@angular/core';
import {RegexFilterModel} from '../models/api/regex-filter.model';

@Pipe({standalone: true, name: 'regexDisplayPipe'})
export class RegexDisplayPipe implements PipeTransform {
    public transform(regex: RegexFilterModel): string {
        if (!regex.regularExpression && (!Array.isArray(regex.flags) || !regex.flags.length)) {
            return null;
        }
        return `/${regex.regularExpression}/${regex.flags.join('')}`;
    }
}
