@if (subscription?.closed) {
    <div class="container">
        <div class="header-row">
            <h1>Account details</h1>
            <nuc-button-bar>
                @if (formGroup?.dirty) {
                    <nuc-button-primary text="Save changes"
                                        [disabled]="!formGroup.valid"
                                        (click)="updateAccount()">
                    </nuc-button-primary>
                }

                @if (user?._id === team?.owner._id) {
                    <nuc-button-secondary text="Transfer ownership"
                                          (click)="onTransferOwnershipButtonClicked()">
                    </nuc-button-secondary>
                }

                <nuc-button-secondary text="Change password"
                                      (click)="onChangePasswordButtonClicked()">
                </nuc-button-secondary>
            </nuc-button-bar>
        </div>

        @if (user) {
            <form [formGroup]="formGroup">
                <h2>General information</h2>

                <nuc-form-field label="Full Name">
                    <nuc-input formControlName="fullName"></nuc-input>
                </nuc-form-field>

                <nuc-form-field label="Email Address">
                    <nuc-input formControlName="email"></nuc-input>
                </nuc-form-field>
            </form>

            <hr>

            <form [formGroup]="formGroup">
                <h2>Notifications</h2>

                <div class="preference-notifications">
                    <div class="notification-header">
                        <div>Notification type</div>
                        <div class="destination">Notification destination</div>
                    </div>
                    <div formGroupName="preferences">
                        <div formArrayName="notifications" class="notification">
                            @for (notificationForm of formGroup.controls.preferences.controls.notifications.controls; track notificationForm; let i = $index) {
                                <notification-preference  [formGroupName]="i" [formGroup]="notificationForm"></notification-preference>
                            }
                        </div>
                    </div>
                </div>
            </form>
        }
    </div>

    <rl-relayter-version></rl-relayter-version>
} @else {
    <rl-loading-indicator></rl-loading-indicator>
}
