@if (formGroup) {
    <form [formGroup]="formGroup">
        <nuc-form-field label="Name">
            <nuc-input formControlName="name" placeholder="Name"></nuc-input>
        </nuc-form-field>

        <nuc-form-field label="Grant types">
            <nuc-dropdown-multiselect formControlName="grant_types" placeholder="Select grant type(s)" [items]="grantTypeOptions"></nuc-dropdown-multiselect>
        </nuc-form-field>

        <nuc-form-field label="Scopes">
            <nuc-dropdown-multiselect
                formControlName="scopes"
                [items]="permissionOptions"
                [searchable]="true"
                (requestData)="requestPermissionOptions($event)"
                placeholder="Select scope(s)">
            </nuc-dropdown-multiselect>
        </nuc-form-field>
    </form>
}
