import {Component, inject, OnInit} from '@angular/core';
import {EJobStatus, JobModel} from '../../../models/api/job.model';
import {AppConstants} from '../../../app.constants';
import {BUTTON_TYPE, EToastType, ToastActionModel, ToastButtonConfig, ToastDataModel} from '@relayter/rubber-duck';
import {ARLogger} from '@relayter/core';
import {Toaster} from '../../../classes/toaster.class';
import {MonitoredJobsService} from '../../../api/services/monitored-updates/monitored-jobs.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {map} from 'rxjs/operators';
import {ENotificationDestination} from '../../../models/api/notification.model';
import {ToasterComponent, ToasterItem} from '../toaster.component';

@Component({
    selector: 'job-toaster',
    styleUrls: ['./../toaster.component.scss'],
    templateUrl: './../toaster.component.html',
    standalone: true
})
export class JobToasterComponent extends ToasterComponent<JobModel> implements OnInit {
    protected monitoredService = inject(MonitoredJobsService);

    private DOWNLOAD_JOBS = Object.keys(AppConstants.JOBS)
        .filter(jobName => AppConstants.JOBS[jobName].download)
        .map(jobName => AppConstants.JOBS[jobName].name);

    /**
     * Starts the monitoring of the job
     */
    public listenToNotifications(): void {
        this.monitoredService.getAllMonitoredItems()
            .pipe(
                map(jobs => jobs.filter(job => this.userService.hasNotificationPreference(job.notificationType, ENotificationDestination.TOASTER))),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: (monitoredItems: JobModel[]) => {
                    monitoredItems.forEach((monitoredItem) => {
                        // make sure we only add toasters for new job items
                        if (this.toasterItems.find(toasterItem => toasterItem.itemId === monitoredItem._id)) return;

                        const toastData = new ToastDataModel(EToastType.NEUTRAL, monitoredItem.title, 'Queued', true);
                        this.toasterItems.push(new ToasterItem(monitoredItem._id, toastData));
                        Toaster.openToast(toastData);

                        this.monitoredService.getItemMonitor(monitoredItem._id)
                            .pipe(takeUntilDestroyed(this.destroyRef))
                            .subscribe({
                                next: (job) => this.updateToastData(job, toastData),
                                error: (error) => ARLogger.error(`jobNotification: ${error}`),
                                complete: () => this.monitoredService.removeMonitoredItem(monitoredItem._id)
                            });
                    });
                },
                error: ARLogger.error
            });
    }

    private updateToastData(job: JobModel, toastData: ToastDataModel): void {
        switch (job.status) {
            case EJobStatus.QUEUED:
                toastData.type = EToastType.NEUTRAL;
                toastData.title = job.title;
                toastData.message = 'Queued';
                toastData.loading = true;
                break;
            case EJobStatus.IN_PROGRESS:
                toastData.type = EToastType.NEUTRAL;
                toastData.title = job.title;
                toastData.message = 'In Progress';
                toastData.loading = true;
                break;
            case EJobStatus.FAILED:
                toastData.type = EToastType.ERROR;
                toastData.title = job.title;
                if (job.result) {
                    toastData.message = `Failed: ${job.result}`;
                } else {
                    toastData.message = 'Failed';
                    toastData.loading = false;
                }
                break;
            case EJobStatus.DONE:
                toastData.type = EToastType.SUCCESS;
                toastData.title = job.title;
                toastData.loading = false;
                if (this.DOWNLOAD_JOBS.includes(job.type)) {
                    toastData.message ='Ready to download';
                    this.setDownloadAction(job, toastData);
                } else {
                    toastData.message = 'Done';
                }
                break;
            default:
                ARLogger.error(`JobNotification: Unhandled Job status ${job.status}`);
        }
    }

    private setDownloadAction(job: JobModel, toastData: ToastDataModel): void {
        const downloadAction = new ToastActionModel(new ToastButtonConfig(BUTTON_TYPE.SECONDARY, 'nucicon_download'));
        downloadAction.observable.subscribe(() => window.open(job.result));

        toastData.addAction(downloadAction);
    }
}
