import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Toaster} from '../../../../classes/toaster.class';
import {AppConstants} from '../../../../app.constants';

import {
    BUTTON_TYPE,
    EColumnSize,
    ESelectionMode,
    ESortOrder,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    IItemClickEvent,
    ISortOptionEvent,
    ITableAction,
    ITableColumn, NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {MatrixUrlParams} from '../../../../models/ui/matrix-url-params.model';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {RLDatePipe} from '../../../../pipes/rl-date.pipe';
import {EJobStatus, JobModel} from '../../../../models/api/job.model';
import {SortDirection} from '@angular/material/sort';
import {MonitoredJobsService} from '../../../../api/services/monitored-updates/monitored-jobs.service';
import {NullUndefinedPipe} from '../../../../pipes/null-undefined.pipe';
import {RLTableComponent} from '../../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../../api/services/user-settings-storage.service';
import {PaginatorService} from '../../../../components/paginator/paginator.service';
import {Subscription} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import FontsService from '../../../../api/services/fonts.service';
import {FontModel} from '../../../../models/api/font.model';
import {FontFormComponent} from '../../../../forms/font-form/font-form.component';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'font-overview',
    templateUrl: 'font-overview.component.html',
    styleUrls: ['font-overview.component.scss'],
    providers: [PaginatorService]
})

export class FontOverviewComponent extends RLTableComponent implements OnInit {
    public readonly tableId = 'font-overview-table';

    public items: FontModel[];
    public total: number;

    public sortProperty: string;
    public sortOrder: SortDirection;
    public pageIndex: number;
    public pageSize: number;
    public disableNextPage: boolean;

    private dataSubscription: Subscription;
    private fontsService = inject(FontsService)

    public columns: ITableColumn[] = [{
        title: 'Family',
        key: 'family',
        sortProperty: 'family',
        size: EColumnSize.LARGE
    }, {
        title: 'Date modified',
        key: 'updatedAt',
        sortProperty: 'updatedAt',
        format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.DEFAULT)
    }, {
        title: 'Date created',
        key: 'createdAt',
        sortProperty: 'createdAt',
        format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.DEFAULT)
    }, {
        title: 'Created by',
        key: 'createdBy.fullName',
        format: (value) => NullUndefinedPipe.transform(value, NullUndefinedPipe.defaultValues.DELETED_USER),
        size: EColumnSize.BASE
    }];
    public actions: ITableAction[];
    public readonly ESelectionMode = ESelectionMode;

    public get loading(): boolean {
        return !this.dataSubscription?.closed;
    }

    constructor(private route: ActivatedRoute,
                private router: Router,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private dialogService: NucDialogService,
                private fullModalService: FullModalService,
                private monitoredJobsService: MonitoredJobsService,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.setTableActions();
        this.initFromRoute();

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((pagination) => {
                if (this.pageSize !== pagination.pageSize || this.pageIndex !== pagination.pageIndex) {
                    this.pageIndex = pagination.pageIndex;
                    this.pageSize = pagination.pageSize;
                }

                this.updateUrl();

                this.getData();
            });
    }

    private setTableActions(): void {
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.PATCH_FONT)) {
            this.actions = this.actions ? this.actions.concat(AppConstants.TABLE_ACTION_TYPES.EDIT) : [AppConstants.TABLE_ACTION_TYPES.EDIT];
        }
        if (this.userIsAllowedToPipe.transform(AppConstants.PERMISSIONS.DELETE_FONT)) {
            this.actions = this.actions ? this.actions.concat(AppConstants.TABLE_ACTION_TYPES.DELETE) : [AppConstants.TABLE_ACTION_TYPES.DELETE];
        }
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;

        const pageIndex = params['pageIndex'] && parseInt(params['pageIndex'], 10) > 0 ? parseInt(params['pageIndex'], 10) : 1;
        this.sortProperty = params['sortProperty'];
        this.sortOrder = params['sortOrder'] && (params['sortOrder'] === 'asc' || params['sortOrder'] === 'desc') ?
            params['sortOrder'] : null;

        this.setPageIndex(pageIndex);
    }

    private setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    private updateUrl(): void {
        this.router.navigate([
            AppConstants.CONTEXT_URL.TEMPLATING,
            AppConstants.CONTEXT_URL.FONTS,
            new MatrixUrlParams(this.pageIndex, null, this.sortProperty, this.sortOrder)]);
    }

    private getData(): void {
        if (this.dataSubscription) this.dataSubscription.unsubscribe();

        const cursor = this.newApiCursor.getCursor(this.pageIndex);
        const offset = (this.pageIndex === 1 || cursor._id) ? 0 : (this.pageIndex - 1) * this.pageSize;

        this.dataSubscription =
            this.fontsService.find(this.pageSize, offset, this.tableSortOptions, cursor, this.searchValue)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (result) => {
                        this.items = result.items;
                        this.disableNextPage = !result.hasNext;

                        if (this.items.length > 0) {
                            const item = this.items[this.items.length - 1];
                            this.newApiCursor.setCursor(this.pageIndex, item);
                        }
                    },
                    error: Toaster.handleApiError
                });
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        const item = event.item as FontModel;
        switch (event.action) {
            case AppConstants.TABLE_ACTION_TYPES.EDIT: {
                this.openFontModel(item);
                break;
            }
            case AppConstants.TABLE_ACTION_TYPES.DELETE: {
                this.openDeleteDialog(item);
                break;
            }
            default:
                return Toaster.notYetImplementedError();
        }
    }

    private openDeleteDialog(item: FontModel): void {
        const deleteDialogConfig = new NucDialogConfigModel('Delete Font',
            `Please confirm that you wish to delete ${item.family}.`);
        const deleteDialog = this.dialogService.openDialog(deleteDialogConfig);
        deleteDialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        deleteDialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            this.deleteFont(item);
        });
    }

    private deleteFont(item: FontModel): void {
        this.fontsService.deleteOne(item._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (res: boolean) => {
                    if (res) {
                        Toaster.success('Font successfully deleted');
                        this.paginatorService.setPageIndex(this.tableId, this.pageIndex);
                    } else {
                        Toaster.error('There was an error while deleting the Font');
                    }
                },
                error: Toaster.handleApiError
            });
    }

    public onTableRowClicked(event: IItemClickEvent): void {
        const font = event.item as FontModel;
        this.router.navigate([
            AppConstants.CONTEXT_URL.TEMPLATING,
            AppConstants.CONTEXT_URL.FONTS,
            font._id]
        );
    }

    public openFontModel(font?: FontModel): void {
        const modalConfig = font ?
            new FullModalConfig('Update a Font file',
                'You can edit the information of the font or upload a new zip file.') :
            new FullModalConfig('Add a Font file',
                'We expect a ZIP file containing all your font files for this font family.');
        modalConfig.confirmClose = true;
        modalConfig.data = { ...(font && {font: font})};

        this.fullModalService.open(FontFormComponent, modalConfig)
            .afterClosed()
            .pipe(filter((result: Record<string, string>) => !!result))
            .subscribe((result) => {
                if (result) {
                    if (!!result.jobId) {
                        this.monitorJob(result.jobId);
                    } else {
                        this.setPageIndex()
                    }
                }
            });
    }

    private monitorJob(jobId: string): void {
        this.monitoredJobsService.getItemMonitor(jobId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((jobModel: JobModel) => {
                if (jobModel.status === EJobStatus.DONE) {
                    this.getData();
                }
            });
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        if (event.column?.sortProperty) {
            this.sortProperty = event.column?.sortProperty;
            this.sortOrder = event.sortOrder === ESortOrder.ASC ? 'asc' : 'desc';
        } else {
            this.sortProperty = '';
            this.sortOrder = '';
        }

        this.setPageIndex();
    }

}
