import {Component, inject, OnInit, signal} from '@angular/core';
import {CollectionDisplayNamePipe} from '../../../../../pipes/collection-display-name.pipe';
import {ComponentsModule} from '../../../../../components/components.module';
import {
    FullModalConfig,
    FullModalService,
    NUCButtonBarModule,
    NUCButtonsModule,
    NUCTooltipModule
} from '@relayter/rubber-duck';
import {PipesModule} from '../../../../../pipes/pipes.module';
import {ActivatedRoute} from '@angular/router';
import {finalize} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Toaster} from '../../../../../classes/toaster.class';
import {OAuthClientApiService} from '../../../../../api/services/oauth-client.api.service';
import {OAuthClientModel} from '../../../../../models/api/oauth-client.model';
import {OAuthClientFormComponent} from '../../../../../forms/oauth-client-form/oauth-client-form.component';
import {RLBaseComponent} from '../../../../../components/rl-base-component/rl-base.component';

@Component({
    selector: 'oauth-client-detail-component',
    templateUrl: './oauth-client-detail.component.html',
    styleUrls: ['./oauth-client-detail.component.scss'],
    standalone: true,
    imports: [
        CollectionDisplayNamePipe,
        ComponentsModule,
        NUCButtonBarModule,
        NUCButtonsModule,
        NUCTooltipModule,
        PipesModule
    ]
})
export class OAuthClientDetailComponent extends RLBaseComponent implements OnInit {
    private activeRoute = inject(ActivatedRoute);
    private oauthClientApiService = inject(OAuthClientApiService)
    private fullModalService = inject(FullModalService);
    private oauthClientId: string;
    public oauthClient = signal<OAuthClientModel>(null);

    public loading: boolean;
    public readonly logo = '/assets/images/logo_oauth_client.svg';

    public ngOnInit(): void {
        this.activeRoute.params.subscribe((params) => {
            this.oauthClientId = params.oauthClientId;
            this.getOAuthClient();
        });
    }

    private getOAuthClient(): void {
        this.loading = true;
        this.oauthClientApiService.findOne(this.oauthClientId)
            .pipe(finalize(() => this.loading = false), takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: oauthClient => this.oauthClient.set(oauthClient),
                error: Toaster.handleApiError
            });
    }

    public editConnection(): void {
        this.loading = true;
        this.oauthClientApiService.findOne(this.oauthClientId)
            .pipe(finalize(() => this.loading = false), takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (oauthClient: OAuthClientModel) => {
                    this.oauthClient.set(oauthClient);

                    const modalConfig = new FullModalConfig('Edit OAuth client',
                        'You can edit OAuth client information.', {oauthClient: this.oauthClient()});
                    modalConfig.confirmClose = true;

                    this.fullModalService
                        .open(OAuthClientFormComponent, modalConfig)
                        .afterClosed().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res) => {
                            return res ? this.getOAuthClient() : null;
                        });
                },
                error: Toaster.handleApiError
            });
    }
}
