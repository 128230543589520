import {Component, inject, OnInit} from '@angular/core';
import {EToastType, ToastDataModel} from '@relayter/rubber-duck';
import {ARLogger} from '@relayter/core';
import {Toaster} from '../../../classes/toaster.class';
import {ETransitionStatus, TransitionItemModel} from '../../../models/api/transition-item.model';
import {MonitoredTransitionsService} from '../../../api/services/monitored-updates/monitored-transitions.service';
import {UserModel} from '../../../models/api/user.model';
import {ErrorConstants} from '../../../api/error.constants';
import {startWith} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ToasterComponent, ToasterItem} from '../toaster.component';

@Component({
    selector: 'transition-toaster',
    styleUrls: ['./../toaster.component.scss'],
    templateUrl: './../toaster.component.html',
    standalone: true
})
export class TransitionToasterComponent extends ToasterComponent<TransitionItemModel> implements OnInit {
    protected monitoredService = inject(MonitoredTransitionsService);

    private user: UserModel;

    /**
     * @param {UserService} userService
     */
    constructor() {
        super();

        this.user = this.userService.getLoggedInUser();
    }

    /**
     * Starts the monitoring of the transition
     */
    public listenToNotifications(): void {
        this.monitoredService.getAllMonitoredItems()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (monitoredItems: TransitionItemModel[]) => {
                    monitoredItems.forEach((monitoredItem) => {
                        // make sure we only add toasters for new transition items
                        if (this.toasterItems.some((toasterItem) => toasterItem.itemId === monitoredItem._id)) return;

                        const toastData = new ToastDataModel(EToastType.NEUTRAL, 'Transition', 'Queued', true);
                        this.toasterItems.push(new ToasterItem(monitoredItem._id, toastData));

                        // Only show toasts for the current user
                        if (monitoredItem.user === this.user._id) {
                            Toaster.openToast(toastData);
                        }

                        this.monitoredService.getItemMonitor(monitoredItem._id)
                            .pipe(
                                startWith(monitoredItem),
                                takeUntilDestroyed(this.destroyRef)
                            )
                            .subscribe({
                                next: (transitionItem) => this.updateToastData(transitionItem, toastData),
                                error: (error) => ARLogger.error(`transitionNotification: ${error}`),
                                complete: () => this.monitoredService.removeMonitoredItem(monitoredItem._id)
                            });
                    });
                },
                error: ARLogger.error
            });
    }

    private updateToastData(transitionItem: TransitionItemModel, toastData: ToastDataModel): void {
        switch (transitionItem.status) {
            case ETransitionStatus.QUEUED:
                toastData.type = EToastType.NEUTRAL;
                toastData.message = 'Successfully scheduled transition of publication item(s)';
                toastData.loading = true;
                break;
            case ETransitionStatus.IN_PROGRESS:
                toastData.type = EToastType.NEUTRAL;
                toastData.message = 'Transition in progress';
                toastData.loading = true;
                break;
            case ETransitionStatus.FAILED:
                // TODO: We need to find a better way to distinguish user errors (404) and inter server errors (500)
                toastData.type = EToastType.ERROR;
                toastData.title = transitionItem.error?.title || toastData.title;
                toastData.message = transitionItem.error?.message || 'Transition failed';
                // Toaster will disappear when there is useful error message to show
                toastData.loading = !(toastData.message === 'Transition failed' ||
                    toastData.message === ErrorConstants.INTERNAL_SERVER_ERROR_MESSAGE);
                break;
            case ETransitionStatus.DONE:
                // When transition is DONE and started by the user, show notification
                // For all other users let them know there is a transition done, and they should refresh the publication items
                if (transitionItem.user === this.user._id) {
                    toastData.type = EToastType.SUCCESS;
                    toastData.loading = false;
                    toastData.message ='Transition completed';
                } else {
                    this.monitoredService.workflowStepItemsChanged(transitionItem);
                }
                break;
            default:
                ARLogger.error(`TransitionNotification: Unhandled transition status ${transitionItem.status}`);
        }
    }
}
