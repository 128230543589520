@import '../../../../sass/variables';

$unread-indicator-size: 8px;
$message-width: 380px;

.notification-centre-item {
    display: grid;
    position: relative;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: $border-radius;
    box-shadow: $elevation-05-DP;
    padding: $spacer-small;
    margin: $spacer-small $spacer-base;
    grid-template-columns: $user-icon-size $message-width;
    grid-column-gap: $spacer-small;
    grid-row-gap: $spacer-small;
    transition: $transition-smooth-animated;

    &:hover {
        box-shadow: $elevation-1-DP;
    }

    &.selected {
        cursor: auto;
        box-shadow: $elevation-1-DP;
        border: 1px solid $color-green;
    }

    /* content specific styling */
    .content {
        p {
           word-break: break-word;
        }

        .note-icon {
            height: 24px;
            width: 24px;
        }

        .title {
            display: grid;
            grid-template-columns: auto 1fr;
            column-gap: $spacer-base;
            margin: 20px auto;

            .note-status {
                height: 24px;
            }
        }

        .details {
            display: grid;
            grid-auto-flow: row;
            row-gap: $spacer-base;

            .row {
                display: block;

                .note-icon {
                    & + span {
                        margin-left: $spacer-small;
                        vertical-align: top;
                    }
                }

            }
            .detail-list {
                display: grid;
                gap: $spacer-tiny;
                grid-auto-flow: inherit;
                flex-direction: inherit;
            }

        }

        .mention-tag {
            padding: 0 4px;
            color: $color-green;
        }
    }

    .detail {
        word-break: break-word;
    }

    .actions {
        grid-column: 2;
        display: grid;
        grid-auto-flow: column;
        grid-column-gap: $spacer-small;
        justify-content: start;
    }

    .unread-indicator {
        position: absolute;
        background: $color-red;
        width: $unread-indicator-size;
        height: $unread-indicator-size;
        border-radius: 50%;
        right: $spacer-small;
        top: $spacer-small;
    }

    .close-button {
        position: absolute;
        right: $spacer-small;
        top: $spacer-small;
    }

    .icon {
        width: $user-icon-size;
        height: $user-icon-size;
        border-radius: 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: $border-1-snow;
        color: $color-light-grey;

        i {
            display: flex
        }

        .background {
            position: absolute;
            width: $user-icon-size;
            height: $user-icon-size;
        }

        &.done {
            .background {
                background-image: url('/assets/images/notifications/loading-indicator-small-complete.svg');
            }
        }

        &.failed {
            color: $color-red;
        }

        &.loading {
            .background {
                background-image: url('/assets/images/notifications/loading-indicator-small-progress.svg');
                animation: loading-rotate 1s infinite ease-in-out;
            }
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin: 30px 0 20px 0;

        img {
            width: 30px;
            height: 30px;
        }

        .header-text {
            font-size: 11px;
        }
    }

    @keyframes loading-rotate {
        0% { transform: rotate(0) }
        100% { transform: rotate(360deg) }
    }
}
