import {ToastDataModel} from '@relayter/rubber-duck';
import {Component, DestroyRef, inject, OnDestroy, OnInit} from '@angular/core';
import {MonitoredService} from '../../api/services/monitored-updates/monitored.service';
import {UserService} from '../../api/services/users.service';
import {IMonitoredItem} from '../../api/services/monitored-updates/monitored-item.interface';

export class ToasterItem {
    constructor(public itemId: string, public toastData: ToastDataModel) {}
}

@Component({
    template: '',
    standalone: true
})
export abstract class ToasterComponent<T extends IMonitoredItem> implements OnInit, OnDestroy {
    protected destroyRef = inject(DestroyRef);
    protected toasterItems: ToasterItem[] = [];
    protected monitoredService: MonitoredService<T>;
    protected userService = inject(UserService);

    public ngOnInit(): void {
        this.listenToNotifications();
    }

    public ngOnDestroy(): void {
        this.toasterItems.forEach((toasterItem) => {
            toasterItem.toastData.loading = false;
        })
    }

    protected abstract listenToNotifications(): void;
}
