<h2>General information</h2>

@if (team) {
    <div class="general-info-container">
        <p class="body-strong">Team name</p>
        <p>{{ team.name }}</p>

        <p class="body-strong">Owner</p>
        <div>
            <p>{{ team.owner.fullName }}</p>
            <p>{{ team.owner.email }}</p>
        </div>

        @if (team.companyName) {
            <p class="body-strong">Company</p>
            <p>{{ team.companyName }}</p>
        }

        @if (team.companyName) {
            <p class="body-strong">Country</p>
            <p>{{ team.country }}</p>
        }

        @if (team.contract?.startDate) {
            <p class="body-strong">Contract start date</p>
            <p>{{ team.contract.startDate | RLDatePipe: dateFormats.DEFAULT }}</p>
        }
    </div>

    <hr>

    <div class="">
        <h2>Team Usage</h2>

        <div class="team-usage">
            <div>
                <h3>Seats</h3>

                <div class="user-count">
                    <nuc-progress-bar [value]="(team.userCount / team.userLimit) * 100"></nuc-progress-bar>
                    <p class="body-strong">Used seats</p>
                    <p>{{ team.userCount }} {{ team.userCount === 1 ? 'user' : 'users' }} out
                        of {{ team.userLimit }} <span class="body-strong grey">total seats</span>
                    </p>
                </div>
            </div>

            <div>
                <h3>Storage</h3>

                <div class="team-storage">
                    <nuc-status-bar [value]="(team.storageUsed / team.storageLimit) * 100"></nuc-status-bar>
                    <p class="body-strong">Used storage</p>
                    <p>
                <span [class.danger]="(team.storageUsed / team.storageLimit) * 100 > 90">
                    {{ team.storageUsed | filesize }}
                </span>
                        out of {{ team.storageLimit | filesize }} <span class="body-strong grey">total storage</span>
                    </p>
                </div>
            </div>

            @if (team.renderCredits) {
                <div>
                    <h3>Render credits</h3>
                    <div class="team-creation">
                        <nuc-segmented-status-bar [segments]="team.renderUsage"
                                                  [total]="team.renderCredits"></nuc-segmented-status-bar>
                        <span class="body-strong">Render credits </span>
                        <p>
                            <span
                            [class.overuse]="totalRenderCreditUsed > team.renderCredits">{{ totalRenderCreditUsed }}</span>
                            used out of {{ team.renderCredits }}</p>

                        @if (totalRenderCreditUsed > team.renderCredits) {
                            <i class="body-strong"> &bullet; </i>
                            <p>
                                <span class="danger body-strong">  {{ totalRenderCreditUsed - team.renderCredits }} overused render credits</span>
                            </p>
                        }

                    </div>
                </div>
            }

            @if (team.publicationItemLimit) {
                <div>
                    <h3>Content items</h3>

                    <div class="team-creation">
                        <nuc-status-bar
                            [value]="(team.publicationItemCreatedInCurrentContractPeriod / team.publicationItemLimit) * 100"></nuc-status-bar>
                        <p class="body-strong">Created Content items - in current period</p>
                        <p>
                        <span
                            [class.danger]="(team.publicationItemCreatedInCurrentContractPeriod / team.publicationItemLimit) * 100 > 90">
                            {{ team.publicationItemCreatedInCurrentContractPeriod }}
                        </span> of {{ team.publicationItemLimit }} <span class="body-strong grey">total creations</span>
                        </p>
                    </div>
                </div>
            }

            @if (team.indesignGenerationLimit) {
                <div>
                    <h3>InDesign generation</h3>

                    <div class="team-creation">
                        <nuc-status-bar
                            [value]="(team.indesignGenerationsInCurrentContractPeriod / team.indesignGenerationLimit) * 100"></nuc-status-bar>
                        <p class="body-strong">InDesign generations - in current period</p>
                        <p>
                        <span
                            [class.danger]="(team.indesignGenerationsInCurrentContractPeriod / team.indesignGenerationLimit) * 100 > 90">
                            {{ team.indesignGenerationsInCurrentContractPeriod }}
                        </span> of {{ team.indesignGenerationLimit }} <span class="body-strong grey">total
                            creations</span>
                        </p>
                    </div>
                </div>
            }

            @if (team.svgGenerationLimit) {
                <div>
                    <h3>SVG generation</h3>

                    <div class="team-creation">
                        <nuc-status-bar
                            [value]="(team.svgGenerationsInCurrentContractPeriod / team.svgGenerationLimit) * 100"></nuc-status-bar>
                        <p class="body-strong">SVG generations - in current period</p>
                        <p>
                        <span
                            [class.danger]="(team.svgGenerationsInCurrentContractPeriod / team.svgGenerationLimit) * 100 > 90">
                            {{ team.svgGenerationsInCurrentContractPeriod }}
                        </span> of {{ team.svgGenerationLimit }} <span class="body-strong grey">total generations</span>
                        </p>
                    </div>
                </div>
            }

            @if (team.afterEffectsGenerationLimit) {
                <div>
                    <h3>After Effects generation</h3>

                    <div class="team-creation">
                        <nuc-status-bar
                            [value]="(team.afterEffectsGenerationsInCurrentContractPeriod / team.afterEffectsGenerationLimit) * 100"></nuc-status-bar>
                        <p class="body-strong">After Effects generations - in current period</p>
                        <p>
                        <span
                            [class.danger]="(team.afterEffectsGenerationsInCurrentContractPeriod / team.afterEffectsGenerationLimit) * 100 > 90">
                            {{ team.afterEffectsGenerationsInCurrentContractPeriod }}
                        </span> of {{ team.afterEffectsGenerationLimit }} <span class="body-strong grey">total
                            generations</span>
                        </p>
                    </div>
                </div>
            }
        </div>
    </div>
} @else if (!(PERMISSIONS.GET_TEAM_DETAILS | userIsAllowedTo)) {
    <nuc-empty-state-component [small]="true"
                               imageUrl="assets/images/empty_states/no_permission_small.svg"
                               [title]="'No permission to view this page. \nPlease contact your administrator.'">
    </nuc-empty-state-component>
} @else {
    <rl-loading-indicator></rl-loading-indicator>
}

<hr>
<p class="section-header">Content creation</p>
<p>Usage statistics about all the content created in all your publications</p>

<form [formGroup]="formGroup" class="usage_filters">
    <nuc-form-field label="Calendar period">
        <nuc-dropdown [items]="periods" formControlName="period" [nullOption]="false"></nuc-dropdown>
    </nuc-form-field>
</form>
<div class="usage">
    <canvas baseChart
            height="300"
            [data]="barChartData"
            [options]="barChartOptions"
            [legend]="barChartLegend"
            [type]="barChartType">
    </canvas>
</div>
