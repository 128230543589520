import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Inject, inject, OnInit} from '@angular/core';
import {TemplateBodyModel, TemplateDetailModel} from '../../../../models/api/template.model';
import {Toaster} from '../../../../classes/toaster.class';
import {ITabBarItem} from '@relayter/rubber-duck/lib/modules/tab-bar/config/tab-bar-item.interface';
import {combineLatest, forkJoin} from 'rxjs';
import {filter, finalize, map, startWith} from 'rxjs/operators';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {EDataFieldCollectionName, EFormStatus} from '../../../../app.enums';
import {VariantModel} from '../../../../models/api/variant.model';
import {VariantService} from '../../../../api/services/variant.service';
import {DataFieldsApiService} from '../../../../api/services/data-fields.api.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {RLValidatorConstants} from '../../../../classes/validators/rl-validators.constant';
import {DropdownItem} from '../../../../models/ui/dropdown-item.model';
import {StaticContentTemplateEditorDataService} from './static-content-template-editor/static-content-template-editor.data-service';
import {FormArrayMinLengthValidator} from '../../../../classes/validators/form-array-min-length.validator';
import {TemplateAreaModel} from '../../../../models/api/template-area.model';
import {StaticContentTemplateSizeModel} from './static-content-template-editor/static-content-template-size.model';
import {
    ITemplatePresetForm,
    StaticContentTemplatePresetComponent
} from './static-content-template-preset/static-content-template-preset.component';
import {ITemplateSizeForm} from './static-content-template-size/static-content-template-size.component';
import {ITemplateAreaForm} from './static-content-template-content/static-content-template-content.component';
import {ITemplateVariantPresetForm} from './static-content-template-variants/preset/template-variant-preset.component';
import {TemplatesApiService} from '../../../../api/services/templates.api.service';
import {ModelUtil} from '../../../../classes/model.util';

export interface ITemplateDetailData {
    template?: TemplateDetailModel;
}

export interface ITemplateForm {
    preset: FormGroup<ITemplatePresetForm>;
    size: FormGroup<ITemplateSizeForm>;
    areas: FormArray<FormGroup<ITemplateAreaForm>>;
    variantPresets: FormArray<FormGroup<ITemplateVariantPresetForm>>;
}
@Component({
    selector: 'static-content-template-form',
    templateUrl: 'static-content-template-form.component.html',
    styleUrls: ['static-content-template-form.component.scss'],
    providers: [StaticContentTemplateEditorDataService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StaticContentTemplateFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    private changeDetectionRef = inject(ChangeDetectorRef);
    public template: TemplateDetailModel;
    public formGroup: FormGroup<ITemplateForm>;

    // tabs related
    public presetTab: ITabBarItem = {title: 'Preset'};
    public sizesTab: ITabBarItem = {title: 'Sizes'};
    public contentTab: ITabBarItem = {title: 'Content'};
    public variantsTab: ITabBarItem = {title: 'Variants'};
    public tabs: ITabBarItem[];
    public activeTab = this.presetTab;

    // variants related
    public variants: VariantModel[];
    public variantsEnabled = false;

    private saveButtonConfig: ButtonConfig;

    constructor(private templatesApiService: TemplatesApiService,
                private fullModalService: FullModalService,
                private templateEditorService: StaticContentTemplateEditorDataService,
                private variantService: VariantService,
                private dataFieldsService: DataFieldsApiService,
                @Inject(NUC_FULL_MODAL_DATA) private modalData: ITemplateDetailData) {
        this.template = this.modalData.template || TemplateDetailModel.defaultTemplate();
    }

    public ngOnInit(): void {
        // On selection of content in template editor, open the content tab
        this.templateEditorService.selectedContentIndexSubject.pipe(
            filter((selectedContentIndex) => selectedContentIndex >= 0),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(() => {
            this.activeTab = this.contentTab;
            this.changeDetectionRef.markForCheck();
        });

        this.initVariantData();
    }

    private initVariantData(): void {
        forkJoin([
            this.variantService.getVariants(),
            this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.CAMPAIGN_ITEM)
        ]).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([variantResults, dataFields]) => {
                    this.variantsEnabled = dataFields.some(field => field.enableVariants);
                    this.variants = variantResults.items;

                    this.tabs = [this.presetTab, this.sizesTab, this.contentTab];
                    if (this.variantsEnabled) this.tabs.push(this.variantsTab);

                    this.initForm();
                    this.initModalButtons();
                    this.changeDetectionRef.markForCheck()
                },
                error: Toaster.handleApiError
            });
    }

    private initForm(): void { // only initialize form once
        const tags = this.template.tags?.map((v) => new DropdownItem(v, v));
        const templateType = this.template.templateType ? [this.template.templateType] : [];
        const selectedEngineTypeOption = new DropdownItem(this.template.engineType, this.template.engineType);
        const selectedChannelOption = new DropdownItem(this.template.channel, this.template.channel);
        const numberOfPages = this.template.staticContent.numberOfPages;
        const selectedNumberOfPagesOption = StaticContentTemplatePresetComponent.numberOfPagesOptions
            .find((option) => option.value === numberOfPages);

        this.formGroup = new FormGroup<ITemplateForm>({
            preset: new FormGroup<ITemplatePresetForm>({
                name: new FormControl(this.template.name, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
                tags: new FormControl(tags),
                templateType: new FormControl(templateType),
                engineType: new FormControl({
                    value: selectedEngineTypeOption,
                    disabled: !!this.template._id
                }, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
                channel: new FormControl({
                    value: selectedChannelOption,
                    disabled: !!this.template._id
                }, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
                masterPage: new FormControl(this.template.staticContent.masterPage),
                showMasterPage: new FormControl(false),
                designLibrary: new FormControl(this.template.staticContent.designLibrary, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
                ruleset: new FormControl(this.template.staticContent.ruleset, RLValidatorConstants.VALIDATOR_SETS.REQUIRED),
                numberOfPages: new FormControl(selectedNumberOfPagesOption, RLValidatorConstants.VALIDATOR_SETS.REQUIRED)
            }),
            size: new FormGroup<ITemplateSizeForm>({
                width: new FormControl(this.template.staticContent.pageSize.width, RLValidatorConstants.VALIDATOR_SETS.GREATER_THAN_ZERO),
                height: new FormControl(this.template.staticContent.pageSize.height, RLValidatorConstants.VALIDATOR_SETS.GREATER_THAN_ZERO),
                margins: new FormGroup({
                    allMarginsTheSame: new FormControl(false),
                    marginTop: new FormControl(this.template.staticContent.margins.marginTop,
                        RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED),
                    marginBottom: new FormControl(this.template.staticContent.margins.marginBottom,
                        RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED),
                    marginEnd: new FormControl(this.template.staticContent.margins.marginEnd,
                        RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED),
                    marginStart: new FormControl(this.template.staticContent.margins.marginStart,
                        RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED)
                })
            }),
            areas: new FormArray<FormGroup<ITemplateAreaForm>>(this.template.staticContent.areas.map((area) => {
                return new FormGroup({
                    _id: new FormControl(area._id),
                    position: new FormGroup({
                        x: new FormControl(area.position.x, RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED),
                        y: new FormControl(area.position.y, RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED)
                    }),
                    size: new FormGroup({
                        width: new FormControl(area.size.width, RLValidatorConstants.VALIDATOR_SETS.GREATER_THAN_ZERO),
                        height: new FormControl(area.size.height, RLValidatorConstants.VALIDATOR_SETS.GREATER_THAN_ZERO)
                    }),
                    columns: new FormControl(area.columns, RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED),
                    rows: new FormControl(area.rows, RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED),
                    columnGutter: new FormControl(area.columnGutter, RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED),
                    rowGutter: new FormControl(area.rowGutter, RLValidatorConstants.VALIDATOR_SETS.POSITIVE_NUMBER_REQUIRED)
                });
            }), FormArrayMinLengthValidator(1)),
            variantPresets: new FormArray<FormGroup<ITemplateVariantPresetForm>>((this.template.variantPresets || []).map((variantPreset) => {
                return new FormGroup({
                    variant: new FormControl(variantPreset.variant, Validators.required),
                    masterPage: new FormControl(variantPreset.staticContent.masterPage),
                    designLibrary: new FormControl(variantPreset.staticContent.designLibrary, Validators.required),
                    ruleset: new FormControl(variantPreset.staticContent.ruleset, Validators.required)
                });
            }))
        });

        // for template editor view
        this.templateEditorService.setTemplateSize(
            new StaticContentTemplateSizeModel(
                this.template.staticContent.pageSize.width,
                this.template.staticContent.pageSize.height,
                this.template.staticContent.margins
            ));
        this.templateEditorService.setNumberOfPages(numberOfPages);
        this.templateEditorService.setChannel(this.template.channel);
        this.templateEditorService.setContents(this.formGroup.get('areas').value as TemplateAreaModel[]);
        this.templateEditorService.setHoveredContentIndex(-1);
    }

    private initModalButtons(): void {
        this.saveButtonConfig = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, null, true);
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        const cancel = new FullModalActionModel(cancelButton);
        cancel.observable.subscribe(() => this.fullModalService.close(false, true));
        const save = new FullModalActionModel(this.saveButtonConfig);

        combineLatest([
            this.formGroup.statusChanges.pipe(startWith(this.formGroup.status), map((status) => status === EFormStatus.VALID)),
            this.templateEditorService.collisionsSubject.pipe(map((collisions) => {
                return collisions.size > 0
            }))
        ]).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(([formValid, hasCollision]) => {
                this.saveButtonConfig.disabled = !formValid || hasCollision;
            });

        save.observable.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.onSaveButtonClicked());
        this.fullModalService.setModalActions([cancel, save]);
    }

    public onActiveTabChanged(tab: ITabBarItem): void {
        // If we navigate from the content tab somewhere else & have a selected template content item, deselect it
        if (tab !== this.contentTab) {
            this.templateEditorService.setSelectedContentIndex(-1);
        }
    }

    public onSaveButtonClicked(): void {
        this.saveButtonConfig.loading = true;

        const body = ModelUtil.createApiBody(
            TemplateBodyModel.fromStaticContentTemplateFormGroup(this.formGroup),
            this.template?._id
        );

        if (this.template._id) {
            this.templatesApiService.put(this.template._id, body)
                .pipe(
                    finalize(() => this.saveButtonConfig.loading = false),
                    takeUntilDestroyed(this.destroyRef)
                )
                .subscribe({
                    next: () => {
                        Toaster.success('Static content template updated successfully');
                        this.fullModalService.close(true);
                    },
                    error: Toaster.handleApiError
                });
        } else {
            this.templatesApiService.create(body)
                .pipe(
                    finalize(() => this.saveButtonConfig.loading = false),
                    takeUntilDestroyed(this.destroyRef)
                )
                .subscribe({
                    next: () => {
                        Toaster.success('Static content template created successfully');
                        this.fullModalService.close(true);
                    },
                    error: Toaster.handleApiError
                });
        }
    }
}
