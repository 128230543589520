<div class="product-detail-page">

    <div class="page-header">
        <div class="page-title">
            <h1>Product details</h1>
        </div>

        <nuc-button-bar class="header-buttons">
            <nuc-button-secondary icon="nucicon_trash_fill" (click)="openDeleteDialog()"
                                  [disabled]="dataSubscription && !dataSubscription.closed"
                                  *ngIf="permissions.DELETE_PRODUCT | userIsAllowedTo"></nuc-button-secondary>

            <nuc-button-secondary icon="nucicon_edit" (click)="onEditProductClicked()" [nucTooltip]="'Edit'"
                                  [disabled]="dataSubscription && !dataSubscription.closed"
                                  *ngIf="permissions.PATCH_PRODUCT | userIsAllowedTo"></nuc-button-secondary>
        </nuc-button-bar>
    </div>
    <ng-container *ngIf="dataSubscription?.closed; else loading">
        <div class="product-info-container" @rlLoadTrigger>
            <nuc-video-player *ngIf="product.assets[0] && (product.assets[0].type | assetFileType) === EAssetType.VIDEO else defaultCard"
                              [src]="product.assets[0].files.preview.url"/>
            <ng-template #defaultCard>
                <nuc-card-view *ngIf="product.assets.length"
                                           [hoverImage]="true"
                                           size="EXTRA_LARGE"
                                           [image]="product.assets[0] | assetDisplay:EAssetDisplayProperties.THUMBNAIL_URL">
                    <nuc-button-secondary imageOptions
                                          *ngIf="product.assets[0] | assetDisplay:EAssetDisplayProperties.SOURCE_URL"
                                          icon="nucicon_download"
                                          (click)="onDownloadOriginalAssetClicked(product.assets[0]?.getSourceURL())"></nuc-button-secondary>
                    <nuc-button-secondary imageOptions
                                          *ngIf="product.assets[0] | assetDisplay:EAssetDisplayProperties.SOURCE_URL"
                                          icon="nucicon_images_line"
                                          (click)="onExportAssetClicked(product.assets[0])"></nuc-button-secondary>

                    <div content>
                        <div class="asset-title">
                            <p class="body-strong">File name</p>
                            <p>{{product.assets[0] | assetDisplay:EAssetDisplayProperties.TITLE}}</p>
                        </div>

                        <div class="asset-content">
                            <div class="item">
                                <p class="body-strong">Asset size</p>
                                <p>{{product.assets[0] | assetDisplay:EAssetDisplayProperties.FILE_INFO}}</p>
                                <p>{{product.assets[0] | assetDisplay:EAssetDisplayProperties.RESOLUTION}}</p>
                                <p>{{product.assets[0] | assetDisplay:EAssetDisplayProperties.PRINT_SIZE_NAME}}</p>
                            </div>
                        </div>
                    </div>
                </nuc-card-view>
            </ng-template>
            <information-details-view-component [item]="product" [dataFields]="dataFields"></information-details-view-component>
        </div>

        <div *ngIf="product.assets?.length > 0" @rlLoadTrigger>
            <hr>

            <div class="asset-info-header">
                <p class="section-header">Product assets</p>
                <p>Assets that are linked to the product</p>
            </div>

            <div class="assets-container">
                <div class="item" *ngFor="let asset of productAssets">
                    <nuc-media-card-view [hoverCard]="true"
                                     [hoverImage]="true"
                                     [title]="asset | assetDisplay:EAssetDisplayProperties.TITLE"
                                     [subTitle]="asset | assetDisplay:EAssetDisplayProperties.SUBTITLE"
                                     [media]="asset | assetDisplay:EAssetDisplayProperties.THUMBNAIL_MEDIA"
                                     [routerLink]="'/assets/' + asset._id">
                        <nuc-button-secondary imageOptions
                                              *ngIf="asset | assetDisplay:EAssetDisplayProperties.SOURCE_URL"
                                              icon="nucicon_download"
                                              (click)="onDownloadOriginalAssetClicked(asset.getSourceURL()); $event.stopPropagation()"></nuc-button-secondary>
                        <nuc-button-secondary imageOptions
                                              *ngIf="(asset | assetDisplay:EAssetDisplayProperties.SOURCE_URL) && (asset.type | assetFileType) === 'Image'"
                                              icon="nucicon_images_line"
                                              (click)="onExportAssetClicked(asset); $event.stopPropagation()"></nuc-button-secondary>
                        <div content>

                            <div class="content">
                                <p class="body-strong">Asset size</p>
                                <p>{{asset | assetDisplay:EAssetDisplayProperties.FILE_INFO}}</p>
                                <p>{{asset | assetDisplay:EAssetDisplayProperties.RESOLUTION}}</p>
                                <p>{{asset | assetDisplay:EAssetDisplayProperties.PRINT_SIZE_NAME}}</p>
                                <p class="body-strong">Date created</p>
                                <p>{{asset | assetDisplay:EAssetDisplayProperties.DATE_CREATED | RLDatePipe:dateFormats.TABLE_DETAILED}}</p>
                                <p class="body-strong">Date modified</p>
                                <p>{{asset | assetDisplay:EAssetDisplayProperties.DATE_MODIFIED | RLDatePipe:dateFormats.TABLE_DETAILED}}</p>
                            </div>
                        </div>
                    </nuc-media-card-view>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #loading>
    <rl-loading-indicator></rl-loading-indicator>
</ng-template>
