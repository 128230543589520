import {inject, Injectable} from '@angular/core';
import {BaseApiRequestService} from './base-api-request.service';
import {Observable} from 'rxjs';
import {ARApiUrlBuilderService, ARPagedResponseDataModel, ARRequestOptions} from '@relayter/core';
import {ApiConstants} from '../api.constant';
import {JobModel} from '../../models/api/job.model';
import {environment} from '../../../environments/environment';
import {QueryParams} from '../../classes/query-params';
import {SortDirection} from '@angular/material/sort';
import {ContentRuleModel, ContentRulePatchModel, ContentRulePostModel} from '../../models/api/content-rule.model';
import {tap} from 'rxjs/operators';
import {MonitoredJobsService} from './monitored-updates/monitored-jobs.service';

export enum EContentRuleJobTypes {
    APPLY_CONTENT_RULES = 'APPLY_CONTENT_RULES'
}

@Injectable({
    providedIn: 'root'
})
export class ContentRuleService extends BaseApiRequestService {
    private monitoredJobsService = inject(MonitoredJobsService);

    private static API_GROUP_CONTENT_RULES = 'content-rules';

    public getContentRules(limit?: number, offset?: number,
                           sortProperty?: string, sortOrder?: SortDirection,
                           search?: string): Observable<ARPagedResponseDataModel<ContentRuleModel>> {
        const queryParams = new QueryParams()
            .setLimitAndOffsetParams(limit, offset)
            .setSortAndSortDirectionParams(sortProperty, sortOrder)
            .setSearchParams(search);

        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ContentRuleService.API_GROUP_CONTENT_RULES], queryParams.getParams());

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.GET;
        options.url = url;
        return new Observable((obs) => this.handlePagedResponse(options, obs, ContentRuleModel));
    }

    public postContentRule(contentRule: ContentRulePostModel): Observable<ContentRuleModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ContentRuleService.API_GROUP_CONTENT_RULES]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = contentRule;
        return new Observable((obs) => this.handleDetailResponse(options, obs, ContentRuleModel));
    }

    public postJob(jobType: EContentRuleJobTypes, jobData?: Record<string, any>): Observable<JobModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ContentRuleService.API_GROUP_CONTENT_RULES,
            ApiConstants.API_METHOD_JOBS]);

        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.POST;
        options.url = url;
        options.body = {jobType, jobData};

        return new Observable((obs) => {
            this.handleDetailResponse(options, obs, JobModel);
        }).pipe(
            tap((job: JobModel) => this.monitoredJobsService.addItemToMonitor(job))
        );
    }

    public patchContentRule(contentRuleId: string, contentRule: ContentRulePatchModel): Observable<ContentRuleModel> {
        const url = ARApiUrlBuilderService.urlFromComponents([
            environment.API_SERVER,
            ApiConstants.API_BASE_PATH,
            ContentRuleService.API_GROUP_CONTENT_RULES, contentRuleId]);
        const options: ARRequestOptions = new ARRequestOptions();
        options.method = ApiConstants.REQUEST_METHODS.PATCH;
        options.url = url;
        options.body = contentRule;
        return new Observable((obs) => this.handleDetailResponse(options, obs, ContentRuleModel));
    }
}
