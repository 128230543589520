import {Component, OnInit} from '@angular/core';
import {UpdateUserModel, UserDetailModel} from '../../../models/api/user.model';
import {UserService} from '../../../api/services/users.service';
import {Toaster} from '../../../classes/toaster.class';
import {ChangePasswordFormComponent} from '../../../forms/change-password-form/change-password-form.component';
import {TeamAccountService} from '../../../api/services/team-account.service';
import {TeamModel} from '../../../models/api/team.model';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';
import {TransferOwnershipFormComponent} from '../../../forms/transfer-ownership-form/transfer-ownership-form.component';
import {RLBaseComponent} from '../../../components/rl-base-component/rl-base.component';
import {FullModalConfig, FullModalService} from '@relayter/rubber-duck';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {forkJoin, of, Subscription} from 'rxjs';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ENotificationDestination, ENotificationType, NotificationDestinationsConfig} from '../../../models/api/notification.model';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {NotificationsDataService} from '../../../api/services/notifications.data-service';

export interface IAccountNotificationPreference {
    type: FormControl<ENotificationType>;
    options: FormControl<DropdownItem<ENotificationDestination>[]>;
}

interface IAccountPreferences {
    notifications: FormArray<FormGroup<IAccountNotificationPreference>>;
}

interface IAccountForm {
    fullName: FormControl<string>;
    email: FormControl<string>;
    preferences: FormGroup<IAccountPreferences>
}

@Component({
    selector: 'rl-account-component',
    templateUrl: 'account.component.html',
    styleUrls: ['account.component.scss']
})
export class AccountComponent extends RLBaseComponent implements OnInit {
    public user: UserDetailModel;
    public team: TeamModel; // to check if user is owner of the team
    public formGroup: FormGroup<IAccountForm>;
    protected subscription: Subscription;

    constructor(private userService: UserService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private fullModalService: FullModalService,
                private teamService: TeamAccountService,
                private notificationsDataService: NotificationsDataService) {
        super();
    }

    public ngOnInit(): void {
        this.getData();
    }

    public onChangePasswordButtonClicked(): void {
        const config = new FullModalConfig(
            'Change your password',
            'You have requested to change the password for your account.');
        config.confirmClose = true;

        this.fullModalService.open(ChangePasswordFormComponent, config);
    }

    public onTransferOwnershipButtonClicked(): void {
        const modalData = {owner: this.user};
        const config = new FullModalConfig(
            'Transfer ownership',
            'Fill in the information below to transfer ownership to another user. When transferred it can only be changed back by the new owner.',
            modalData);
        config.confirmClose = true;

        this.fullModalService.open(TransferOwnershipFormComponent, config)
            .afterClosed().subscribe((res) => res ? this.getData() : null);
    }

    /**
     * on Init get user and team details
     */
    private getData(): void {
        this.subscription = forkJoin([
            this.userService.getMe(),
            this.userIsAllowedToPipe.transform(this.permissions.GET_TEAM_DETAILS) ?
                this.teamService.getTeamDetails() : of(null)
        ])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([user, team]) => {
                    this.user = user;
                    this.team = team;

                    this.setupForm();
                },
                error: Toaster.handleApiError
            });
    }

    public updateAccount(): void {
        const updatedUser = new UpdateUserModel();
        updatedUser.fullName = this.formGroup.value.fullName;
        updatedUser.preferences = {
            notifications: this.formGroup.controls.preferences.controls.notifications.value
                .filter(notification => !!notification.options)
                .map(notification =>
                    ({
                        type: notification.type,
                        options: notification.options.map(option => option.getValue())
                    }))
        };

        this.userService.patchUserDetails(this.user._id, updatedUser)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (updatedUser) => {
                    Toaster.success('Updated user successfully');
                    this.user = updatedUser;
                    this.formGroup.patchValue({
                        fullName: updatedUser.fullName,
                        preferences: {
                            notifications: updatedUser.preferences?.notifications.map(notification => ({
                                type: notification.type,
                                options: notification.options.map(option =>
                                    new DropdownItem<ENotificationDestination>(NotificationDestinationsConfig[option],
                                        ENotificationDestination[option]))
                            }))
                        }
                    });
                    this.formGroup.markAsPristine();
                    // Get notifications, to trigger 'New notifications' icon in the sub-menu after updating notification preferences
                    this.notificationsDataService.getNotifications();
                },
                error: Toaster.handleApiError
            });
    }

    private setupForm() {
        this.formGroup = new FormGroup({
            fullName: new FormControl(this.user?.fullName, Validators.required),
            email: new FormControl({value: this.user?.email, disabled: true}), // cannot edit email
            preferences: new FormGroup({
                notifications: new FormArray<FormGroup>(Object.keys(ENotificationType).map(notificationType => {
                    return new FormGroup({
                        type: new FormControl(notificationType),
                        options: new FormControl(this.user.preferences?.notifications?.find(notification =>
                            notification.type === notificationType)?.options
                            .map(option =>
                                new DropdownItem<ENotificationDestination>(NotificationDestinationsConfig[option], ENotificationDestination[option])))
                    })
                }))
            })
        });
    }
}
