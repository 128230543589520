import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {
    BUTTON_TYPE,
    ButtonConfig,
    FullModalActionModel,
    FullModalService,
    NUC_FULL_MODAL_DATA, NUCComponentsModule, NUCInputsModule
} from '@relayter/rubber-duck';
import {distinctUntilChanged, finalize, map, startWith, switchMap} from 'rxjs/operators';
import {EFormStatus} from '../../app.enums';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {EGrantType, OAuthClientModel} from '../../models/api/oauth-client.model';
import {NgIf} from '@angular/common';
import {OAuthClientApiService} from '../../api/services/oauth-client.api.service';
import {ModelUtil} from '../../classes/model.util';
import {Toaster} from '../../classes/toaster.class';
import {Observable} from 'rxjs';
import {PermissionModel} from '../../models/api/permission.model';
import {PermissionsService} from '../../api/services/permissions.service';
import {IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {StringUtil} from '../../classes/string-util';

export interface IOAuthClientFormData {
    oauthClient?: OAuthClientModel;
}

interface OAuthClientFormGroup {
    name: FormControl<string>;
    grant_types: FormControl<DropdownItem<EGrantType>[]>;
    scopes: FormControl<PermissionModel[]>;
}

@Component({
    selector: 'oauth-client-form-component',
    templateUrl: 'oauth-client-form.component.html',
    styleUrls: ['oauth-client-form.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NUCComponentsModule,
        NUCInputsModule,
        NgIf
    ]
})
export class OAuthClientFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);

    public formGroup: FormGroup<OAuthClientFormGroup>;
    private modalData: IOAuthClientFormData = inject(NUC_FULL_MODAL_DATA);
    private readonly oauthClient: OAuthClientModel;
    private fullModalService: FullModalService = inject(FullModalService);
    private oAuthClientApiService = inject(OAuthClientApiService);
    private permissionsService = inject(PermissionsService);

    private permissions: PermissionModel[];
    public permissionOptions: PermissionModel[];

    public grantTypeOptions =
        Object.values(EGrantType).map((grantType) => {
            return new DropdownItem<EGrantType>(grantType, grantType);
        });

    constructor() {
        this.oauthClient = this.modalData.oauthClient;
    }

    public ngOnInit(): void {
        this.permissionsService.getAllPermissions('key', 'asc')
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.permissions = result;
                    this.permissionOptions = this.permissions;
                    this.setupForm();
                    this.setupModalActions();
                },
                error: Toaster.handleApiError
            })
    }

    private setupForm(): void {
        const selectedPermissions = this.permissionOptions.filter((v) =>
            this.oauthClient?.scopes.includes(v.getValue()));

        const selectedGrantTypes = this.grantTypeOptions.filter((v) =>
            this.oauthClient?.grant_types.includes(v.getValue()));

        this.formGroup = new FormGroup({
            name: new FormControl(this.oauthClient?.name, Validators.required),
            grant_types: new FormControl(selectedGrantTypes, Validators.required),
            scopes: new FormControl(selectedPermissions)
        });
    }

    private setupModalActions(): void {
        const saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, null);
        const saveAction = new FullModalActionModel(saveButton);
        const cancelAction = new FullModalActionModel(new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel'));

        cancelAction.observable.subscribe(() => this.fullModalService.close(null, true));
        saveAction.observable.pipe(
            switchMap(() => {
                saveButton.loading = true;
                return this.getAddOrEditObservable();
            }),
            finalize(() => saveButton.loading = false),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe({
            next: (oauthClient: OAuthClientModel) => {
                const operation = this.oauthClient ? 'updated' : 'created';
                Toaster.success(`Successfully ${operation} OAuth client`);
                this.fullModalService.close(oauthClient);
            },
            error: Toaster.handleApiError
        });

        this.fullModalService.setModalActions([cancelAction, saveAction]);

        this.formGroup.statusChanges.pipe(
            startWith(this.formGroup.status),
            distinctUntilChanged(),
            map((status) => status === EFormStatus.VALID),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((formValid) => saveButton.disabled = !formValid);
    }

    private getAddOrEditObservable(): Observable<OAuthClientModel> {
        const formValue = this.formGroup.value;
        const body = ModelUtil.createApiBody({
            name: formValue.name,
            grant_types: formValue.grant_types.map((v) => v.getValue()),
            scopes: formValue.scopes.map((v) => v.getValue()),
        }, this.oauthClient?._id);

        return this.oauthClient
            ? this.oAuthClientApiService.patch(this.oauthClient._id, body)
            : this.oAuthClientApiService.create(body);
    }

    public requestPermissionOptions(event: IDropdownRequestDataEvent): void {
        if (event.reset) this.permissionOptions = [];
        if (event.search) {
            const regex = new RegExp(StringUtil.escapeRegExp(event.search), 'i');
            this.permissionOptions = this.permissions.filter((permission) =>
                permission.name.match(regex)?.length > 0)
        } else {
            this.permissionOptions = this.permissions;
        }
    }
}
