import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AnimatedContentRulesetApiService} from '../../../api/services/animated-content-ruleset.api.service';
import {
    AnimatedContentRulesetModel,
    AnimatedContentRulesetRuleModel
} from '../../../models/api/animated-content-ruleset.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Toaster} from '../../../classes/toaster.class';
import {
    BUTTON_TYPE,
    EColumnDataType,
    EColumnType,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    ISortOptionEvent,
    ITableAction,
    ITableColumn,
    NUCButtonsModule,
    NucDialogConfigModel,
    NucDialogService,
    NUCInputsModule,
    NUCTableModule
} from '@relayter/rubber-duck';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {PipesModule} from '../../../pipes/pipes.module';
import {
    AnimatedContentRulesetRuleFormComponent
} from '../animated-content-ruleset-rule-form/animated-content-ruleset-rule-form.component';
import {EPropertyContext, PropertyService} from '../../../api/services/property.service';
import {RulePropertyModel} from '../../../models/api/rule-property.model';
import {AfterEffectsProjectFilesApiService} from '../../../api/services/after-effects-project-files.api.service';
import {filter, switchMap} from 'rxjs/operators';
import {AfterEffectsProjectFileCompositionModel} from '../../../models/api/after-effects-project-file.model';
import {ComponentsModule} from '../../../components/components.module';
import {RLTableComponent} from '../../../components/rl-base-component/rl-table.component';
import {Subscription} from 'rxjs';
import {RLDatePipe} from '../../../pipes/rl-date.pipe';
import {UserSettingsStorageService} from '../../../api/services/user-settings-storage.service';
import {AppConstants} from '../../../app.constants';
import {PaginatorService} from '../../../components/paginator/paginator.service';
import {MatrixUrlParams} from '../../../models/ui/matrix-url-params.model';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';

@Component({
  selector: 'animated-content-ruleset-detail',
  templateUrl: './animated-content-ruleset-detail.component.html',
  styleUrl: './animated-content-ruleset-detail.component.scss',
  imports: [
      NUCButtonsModule,
      MatMenu,
      PipesModule,
      MatMenuItem,
      MatMenuTrigger,
      ComponentsModule,
      NUCInputsModule,
      NUCTableModule
  ],
  providers: [PaginatorService],
  standalone: true,
})
export class AnimatedContentRulesetDetailComponent extends RLTableComponent implements OnInit {
    public tableId = 'animated-content-ruleset-rules-overview-table';

    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private animatedContentRulesetApiService = inject(AnimatedContentRulesetApiService);
    private afterEffectsProjectFilesApiService = inject(AfterEffectsProjectFilesApiService);
    private paginatorService = inject(PaginatorService);
    private fullModalService = inject (FullModalService);
    private propertyService = inject(PropertyService);
    private userIsAllowedPipe = inject(UserIsAllowedToPipe);
    private dialogService = inject(NucDialogService);

    public total: number;
    public pageIndex: number;
    public pageSize: number;
    public disableNextPage = true;

    public items: AnimatedContentRulesetRuleModel[];
    public actions: ITableAction[] = [];

    private dataSubscription: Subscription;

    public animatedContentRulesetId: string;
    public animatedContentRuleset: AnimatedContentRulesetModel;

    public ruleProperties: RulePropertyModel[];
    public assetRuleProperties: RulePropertyModel[];
    public compositions: AfterEffectsProjectFileCompositionModel[];

    public get loading(): boolean {
        return !this.dataSubscription?.closed;
    }

    public columns: ITableColumn[] = [
        {
            title: 'Name',
            key: 'name',
            sortProperty: 'name',
            type: EColumnType.DEFAULT
        },
        {
            title: 'Composition',
            key: 'composition',
            sortProperty: 'composition',
            type: EColumnType.DEFAULT
        },
        {
            title: 'Date modified',
            key: 'updatedAt',
            sortProperty: 'updatedAt',
            sortDuplicates: true,
            dataType: EColumnDataType.DATE,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        },
        {
            title: 'Date created',
            key: 'createdAt',
            sortProperty: 'createdAt',
            sortDuplicates: true,
            dataType: EColumnDataType.DATE,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        }
    ];

    constructor() {
        super(inject(UserSettingsStorageService));
    }

    public ngOnInit(): void {
        this.initFromRoute();
        this.listenToPagination();
        this.setTableActions();

        this.getAnimatedContentRuleset();
        this.getRulesetProperties();
        this.getRulesetAssetProperties();
    }

    private initFromRoute(): void {
        const params = this.route.snapshot.params;
        this.animatedContentRulesetId = params['animatedContentRulesetId'];
        const pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : 1;
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
        this.tableSortOptions.fromRoute(params, this.columns);
    }

    private listenToPagination(): void {
        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(pagination => {
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;
                if (this.animatedContentRulesetId) {
                    this.updateUrl();
                    this.getAnimatedContentRulesetRules();
                }
            });
    }

    private setTableActions(): void {
        if (this.userIsAllowedPipe.transform(AppConstants.PERMISSIONS.PATCH_ANIMATED_CONTENT_RULESET_RULE)) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.EDIT);
        }
        if (this.userIsAllowedPipe.transform(AppConstants.PERMISSIONS.DELETE_ANIMATED_CONTENT_RULESET_RULE)) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.DELETE);
        }
    }

    private updateUrl(): void {
        const matrixUrl = new MatrixUrlParams(this.pageIndex, null,
            this.tableSortOptions.sortPropertiesAsString, this.tableSortOptions.sortOrder);

        this.router.navigate([
            AppConstants.CONTEXT_URL.TEMPLATING,
            AppConstants.CONTEXT_URL.ANIMATED_CONTENT_RULESETS,
            this.animatedContentRulesetId,
            matrixUrl], {replaceUrl: true});
    }

    private getAnimatedContentRuleset(): void {
        this.animatedContentRulesetApiService.findOne(this.animatedContentRulesetId)
            .pipe(
                switchMap((result) => {
                    this.animatedContentRuleset = result;
                    return this.afterEffectsProjectFilesApiService.find(
                        null,
                        null,
                        null,
                        null,
                        null,
                        {
                            _id: this.animatedContentRuleset.afterEffectsProjectFiles
                                .map((afterEffectsProjectFile) => afterEffectsProjectFile._id)
                        },
                    ).pipe(takeUntilDestroyed(this.destroyRef))
                }),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                    next: (result) => {
                        this.compositions = result.items.reduce((acc, item) => {
                            for (const composition of item.compositions) {
                                const foundComposition = acc.find((compositionItem) => composition.name === compositionItem.name);
                                if (!foundComposition) acc.push(...item.compositions);
                            }
                            return acc;
                        }, [])
                    },
                    error: Toaster.handleApiError
                }
            );
    }

    private getAnimatedContentRulesetRules(): void {
        if (this.dataSubscription) this.dataSubscription.unsubscribe();

        const offset = (this.pageIndex - 1) * this.pageSize;

        this.dataSubscription =
            this.animatedContentRulesetApiService.getAnimatedContentRulesetRules(
                this.animatedContentRulesetId,
                this.pageSize,
                offset,
                this.tableSortOptions
            )
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (result) => {
                        this.items = result.items;
                        this.disableNextPage = !result.hasNext;
                    },
                    error: Toaster.handleApiError
                });
    }

    public openRuleModal(animatedContentRulesetRule?: AnimatedContentRulesetRuleModel): void {
        const modalConfig = animatedContentRulesetRule ?
            new FullModalConfig('Update rule',
                'Setup the conditions, values and assets in order to render the composition.') :
            new FullModalConfig('Add rule',
                'Setup the conditions, values and assets in order to render the composition.');

        const data = {
            ruleSetId: this.animatedContentRulesetId,
            item: animatedContentRulesetRule,
            ruleProperties: this.ruleProperties,
            assetRuleProperties: this.assetRuleProperties,
            compositions: this.compositions
        };

        modalConfig.confirmClose = true;
        modalConfig.data = data;

        this.fullModalService.open(AnimatedContentRulesetRuleFormComponent, modalConfig)
            .afterClosed()
            .pipe(
                filter((result) => !!result),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe((result) => {
                if (result) {
                    this.setPageIndex();
                }
            });
    }

    private getRulesetProperties(): void {
        this.propertyService.getProperties(EPropertyContext.ANIMATED_CONTENT_RULESET)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.ruleProperties = result.items;
                },
                error: Toaster.handleApiError
            });
    }

    private getRulesetAssetProperties(): void {
        this.propertyService.getProperties(EPropertyContext.ANIMATED_CONTENT_RULESET_ASSETS)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.assetRuleProperties = result.items;
                },
                error: Toaster.handleApiError
            });
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        this.tableSortOptions.updateWithSortOptionEvent(sortEvent);
        this.setPageIndex();
    }

    private setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        const rule = event.item as AnimatedContentRulesetRuleModel;
        this.animatedContentRulesetApiService.getAnimatedContentRulesetRule(this.animatedContentRulesetId, rule._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (animatedContentRulesetRule) => {
                    switch (event.action) {
                        case AppConstants.TABLE_ACTION_TYPES.EDIT:
                            this.openRuleModal(animatedContentRulesetRule)
                            break;
                        case AppConstants.TABLE_ACTION_TYPES.DELETE:
                            this.openDeleteDialog(animatedContentRulesetRule)
                            break;
                    }
                },
                error: Toaster.handleApiError
            });
    }

    private openDeleteDialog(rule: AnimatedContentRulesetRuleModel): void {
        const dialogConfig =
            new NucDialogConfigModel('Delete rule', `Please confirm that you wish to delete rule: ${rule.name || ''}`);
        const dialog = this.dialogService.openDialog(dialogConfig);
        dialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => dialog.close());
        dialogConfig.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            this.deleteRule(rule);
            dialog.close();
        });
    }

    private deleteRule(rule: AnimatedContentRulesetRuleModel): void {
        this.animatedContentRulesetApiService.deleteAnimatedContentRulesetRule(this.animatedContentRulesetId, rule._id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                    Toaster.success('Successfully deleted rule');
                    this.setPageIndex();
                },
                Toaster.handleApiError);
    }
}
