import {
    StaticContentRulesetItemFormComponent,
    IFormatRulesetItemFormComponentData
} from '../static-content-ruleset-item-form/static-content-ruleset-item-form.component';
import {DesignLibraryModel} from '../models/api/design-library.model';
import {StaticContentRulesetModel} from '../models/api/static-content-ruleset.model';
import {Component, OnInit, ViewChild} from '@angular/core';
import {FullModalConfig, FullModalService, ITableAction, ITableColumn} from '@relayter/rubber-duck';
import {StaticContentRulesetItemGroupModel} from '../models/api/static-content-ruleset-item-group.model';
import {StaticContentRulesetBaseItem, StaticContentRulesetItemModel} from '../models/api/static-content-ruleset-item.model';
import {StaticContentRulesetService} from '../../../api/services/static-content-ruleset.service';
import {ActivatedRoute} from '@angular/router';
import {SortDirection} from '@angular/material/sort';
import {RLTableComponent} from '../../../components/rl-base-component/rl-table.component';
import {
    StaticContentRulesetAssetItemTableComponent
} from './static-content-ruleset-item-table/static-content-ruleset-asset-item-table/static-content-ruleset-asset-item-table.component';
import {StaticContentRulesetAssetItemModel} from '../models/api/static-content-ruleset-asset-item.model';
import {RulePropertyModel} from '../../../models/api/rule-property.model';
import {Observable, Subscription} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {AppConstants} from '../../../app.constants';
import {PaginatorService} from '../../../components/paginator/paginator.service';
import {EPropertyContext, PropertyService} from '../../../api/services/property.service';
import {StaticContentRulesetItemTableComponent} from './static-content-ruleset-item-table/static-content-ruleset-item-table.component';
import {TabBarItemModel} from '../../../models/ui/tab-bar-item.model';
import {Toaster} from '../../../classes/toaster.class';
import {TabBarRoutableItem} from '../../../pages/relayter/team-settings/tab-bar-routable-item.model';
import {ERulesetContext} from '../../../forms/rule-form/base-ruleset-item-form.component';
import {UserSettingsStorageService} from '../../../api/services/user-settings-storage.service';
import {DesignLibraryItemModel} from '../models/api/design-library-item.model';
import {
    StaticContentRulesetItemGroupFormComponent,
    IFormatRulesetItemGroupFormComponentData
} from '../static-content-ruleset-item-group-form/static-content-ruleset-item-group-form.component';
import {
    StaticContentRulesetItemGroupTableComponent
} from './static-content-ruleset-item-table/static-content-ruleset-item-group-table/static-content-ruleset-item-group-table.component';
import {
    StaticContentRulesetAssetItemFormComponent,
    IFormatRulesetAssetItemFormComponentData
} from '../static-content-ruleset-asset-item-form/static-content-ruleset-asset-item-form.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DesignLibraryApiService} from '../../../api/services/design-library.api.service';

@Component({
    selector: 'static-content-ruleset-detail',
    templateUrl: './static-content-ruleset-detail.component.html',
    styleUrls: ['./static-content-ruleset-detail.component.scss'],
    providers: [PaginatorService]
})
export class StaticContentRulesetDetailComponent extends RLTableComponent implements OnInit {
    @ViewChild('rulesetItemTable') public rulesetItemTable: StaticContentRulesetItemTableComponent;
    @ViewChild('rulesetAssetItemTable') public rulesetAssetItemTable: StaticContentRulesetAssetItemTableComponent;
    @ViewChild('rulesetItemGroupTable') public rulesetItemGroupTable: StaticContentRulesetItemGroupTableComponent;

    public tableId: string;

    public subscription: Subscription;
    public ruleSet: StaticContentRulesetModel;

    public pageIndex: number = AppConstants.PAGE_INDEX_DEFAULT;
    public pageSize: number = AppConstants.PAGE_SIZE_DEFAULT;
    public sortProperty: string;
    public sortOrder: SortDirection;
    public search: string;

    public actions: ITableAction[];
    public columns: ITableColumn[] = [];
    public items: StaticContentRulesetBaseItem[];
    public total: number;
    public disableNextPage = false;
    public TAB_ITEMS = 0;
    public TAB_ASSET_ITEMS = 1;
    public TAB_GROUP_ITEMS = 2;
    public itemsTab = new TabBarItemModel('Library items rules', this.TAB_ITEMS);
    public assetItemsTab = new TabBarItemModel('Asset rules', this.TAB_ASSET_ITEMS);
    public groupItemsTab = new TabBarItemModel('Library item groups', this.TAB_GROUP_ITEMS);
    public tabBarItems: TabBarItemModel[] = [
        this.itemsTab,
        this.assetItemsTab,
        this.groupItemsTab];
    public activeTab: TabBarRoutableItem;
    public ruleSetId: string;
    public ruleProperties: RulePropertyModel[];

    constructor(userSettingsStorageService: UserSettingsStorageService,
                private formatRulesetService: StaticContentRulesetService,
                private designLibraryApiService: DesignLibraryApiService,
                private propertyService: PropertyService,
                private route: ActivatedRoute,
                private fullModalService: FullModalService) {
        super(userSettingsStorageService);
    }

    private _selectedTab = this.tabBarItems[this.TAB_ITEMS];

    public get selectedTab(): TabBarItemModel {
        return this._selectedTab;
    }

    public set selectedTab(tab: TabBarItemModel) {
        if (tab !== this._selectedTab) {
            const index = this.tabBarItems.find((t) => t.title === tab.title).index;
            this._selectedTab = tab;
            this._selectedTab.index = index;
        }
    }

    public ngOnInit(): void {
        this.ruleSetId = this.route.snapshot.params.ruleSetId;
        this.getRuleset();
        this.getRulesetProperties();
    }

    public openRuleItemModal(rulesetItem?: StaticContentRulesetItemModel): void {
        this.getLibraryItems().subscribe({
            next: (libraryItems) => {
                const data = {
                    ruleSetId: this.ruleSetId,
                    item: rulesetItem,
                    ruleProperties: this.ruleProperties,
                    libraryItems,
                    context: ERulesetContext.ITEMS
                } as IFormatRulesetItemFormComponentData;
                const config = new FullModalConfig(rulesetItem ? 'Edit Rule' : 'Create Rule', 'Setup your rule details', data);
                config.confirmClose = true;
                this.fullModalService.open(StaticContentRulesetItemFormComponent, config).afterClosed().pipe(
                    filter((result) => !!result)
                ).subscribe(() => {
                    // only reload table when item edited/created is in current tab
                    if (this.selectedTab === this.tabBarItems[this.TAB_ITEMS]) this.rulesetItemTable.reloadTable();
                });
            },
            error: Toaster.handleApiError
        });
    }

    public openRuleAssetItemModal(item?: StaticContentRulesetAssetItemModel): void {
        const data = {
            ruleSetId: this.ruleSetId,
            item,
            ruleProperties: this.ruleProperties,
            context: ERulesetContext.ASSET_ITEMS
        } as IFormatRulesetAssetItemFormComponentData;
        const config = new FullModalConfig(item ? 'Edit Asset Rule' : 'Create Asset Rule', 'Setup your asset rule details', data);
        config.confirmClose = true;
        this.fullModalService.open(StaticContentRulesetAssetItemFormComponent, config).afterClosed().pipe(
            filter((result) => !!result)
        ).subscribe(() => {
            // only reload table when item edited/created is in current tab
            if (this.selectedTab === this.tabBarItems[this.TAB_ASSET_ITEMS]) this.rulesetAssetItemTable.reloadTable();
        });
    }

    public openRuleGroupItemModal(item?: StaticContentRulesetItemGroupModel): void {
        const config = new FullModalConfig(
            item ? 'Edit Item Group' : 'Create Item Group',
            'Setup your item group details',
            {
                ruleSetId: this.ruleSetId,
                itemGroup: item
            } as IFormatRulesetItemGroupFormComponentData);
        config.confirmClose = true;
        this.fullModalService.open(StaticContentRulesetItemGroupFormComponent, config).afterClosed().pipe(
            filter((result) => !!result)
        ).subscribe(() => {
            // only reload table when item edited/created is in current tab
            if (this.selectedTab === this.tabBarItems[this.TAB_GROUP_ITEMS]) this.rulesetItemGroupTable.reloadTable();
        });
    }

    private getRuleset(): void {
        this.formatRulesetService.getStaticContentRuleset(this.ruleSetId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => this.ruleSet = result,
                error: Toaster.handleApiError
            });
    }

    /**
     * get a list of possible campaign item properties based on the teamID in the url
     */
    private getRulesetProperties(): void {
        this.propertyService.getProperties(EPropertyContext.INDESIGN_RULESET)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => this.ruleProperties = result.items,
                error: Toaster.handleApiError
            });
    }

    private getLibraryItems(): Observable<DesignLibraryItemModel[]> {
        return this.formatRulesetService.getStaticContentRuleset(this.ruleSetId).pipe(
            switchMap((result) => {
                return this.designLibraryApiService
                .find(
                    AppConstants.PAGE_SIZE_MAX,
                    0,
                    undefined,
                    undefined,
                    undefined,
                    {
                        designLibraryIds: result.libraries,
                        engineTypes: result.engineTypes
                    }
                )
                .pipe(
                    map((pagedResult) => this.groupLibraryItems(pagedResult.items)),
                    takeUntilDestroyed(this.destroyRef)
                );
            })
        );
    }

    private groupLibraryItems(libraries: DesignLibraryModel[]): DesignLibraryItemModel[] {
        return libraries
            .reduce((acc, val) => {
                for (const item of val.items) {
                    const foundItem = acc.find((i) => i.name === item.name);
                    foundItem ? foundItem.tags = foundItem.tags.concat(item.tags) : acc.push(item);
                }
                return acc;
            }, [])
            .map((item) => {
                item.tags = [...new Set(item.tags)];
                return item;
            });
    }
}
