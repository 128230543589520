import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {Toaster} from '../../../../../classes/toaster.class';
import {Subscription} from 'rxjs';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {TemplateTypeModel} from '../../../../../models/api/template-type.model';
import {TemplateTypeService} from '../../../../../api/services/template-types.service';
import {MasterPageModel} from '../../../../../models/api/master-page.model';
import {TemplateMarginsModel} from '../../../../../models/api/template-margins.model';
import {DropdownItem} from '../../../../../models/ui/dropdown-item.model';
import {EChannel} from '../../../../../app.enums';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {EEngineType, EPageType} from '../../../../../models/api/template.model';
import {StaticContentTemplateSizeModel} from '../static-content-template-editor/static-content-template-size.model';
import {StaticContentTemplateEditorDataService} from '../static-content-template-editor/static-content-template-editor.data-service';
import {distinctUntilChanged} from 'rxjs/operators';
import {DesignLibraryModel} from '../../../../../modules/static-content-rulesets/models/api/design-library.model';
import {
    StaticContentRulesetModel
} from '../../../../../modules/static-content-rulesets/models/api/static-content-ruleset.model';
import {ITemplateSizeForm} from '../static-content-template-size/static-content-template-size.component';
import {ITemplateVariantPresetForm} from '../static-content-template-variants/preset/template-variant-preset.component';
import {ITemplateForm} from '../static-content-template-form.component';

export interface ITemplatePresetForm {
    name: FormControl<string>;
    tags: FormControl<DropdownItem<string>[]>;
    templateType: FormControl<TemplateTypeModel[]>;
    engineType: FormControl<DropdownItem<EEngineType>>;
    channel: FormControl<DropdownItem<EChannel>>;
    masterPage: FormControl<MasterPageModel>;
    showMasterPage: FormControl<boolean>,
    designLibrary: FormControl<DesignLibraryModel>;
    ruleset: FormControl<StaticContentRulesetModel>;
    numberOfPages: FormControl<{ label: string, value: number, image: string }>;
}

@Component({
    selector: 'static-content-template-preset',
    templateUrl: 'static-content-template-preset.component.html',
    styleUrls: ['static-content-template-preset.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StaticContentTemplatePresetComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    private changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
    @Input() private formGroup: FormGroup<ITemplateForm>;

    static numberOfPagesOptions = [
        { label: EPageType.Single, value: 1, image: '/assets/images/single_page.svg' },
        { label: EPageType.Spread, value: 2, image: '/assets/images/spread_page.svg' }
    ];

    public channel: EChannel;
    public engineType: EEngineType;
    public channelOptions = Object.values(EChannel).map((v) => new DropdownItem(v, v));
    public engineTypeOptions = [new DropdownItem(EEngineType.INDESIGN, EEngineType.INDESIGN), new DropdownItem(EEngineType.SVG, EEngineType.SVG)];
    public numberOfPagesOptions = StaticContentTemplatePresetComponent.numberOfPagesOptions;
    public templateTypes: TemplateTypeModel[];
    public tagOptions: DropdownItem<string>[] = [];

    public templateTypeSubscription: Subscription;
    public addTemplateTypeSubscription: Subscription;

    public presetFormGroup: FormGroup<ITemplatePresetForm>;
    public sizeFormGroup: FormGroup<ITemplateSizeForm>;
    public variantPresetsFormArray: FormArray<FormGroup<ITemplateVariantPresetForm>>;

    constructor(private templateEditorDataService: StaticContentTemplateEditorDataService,
                private templateTypeService: TemplateTypeService) {
    }

    public ngOnInit(): void {
        this.presetFormGroup = this.formGroup.controls.preset;
        this.sizeFormGroup = this.formGroup.controls.size;
        this.variantPresetsFormArray = this.formGroup.controls.variantPresets;
        this.engineType = this.formGroup.controls.preset.controls.engineType.value?.getValue();
        this.listenToFormValueChanges();
    }

    private listenToFormValueChanges(): void {
        this.presetFormGroup.controls.engineType.valueChanges.pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((engineType: DropdownItem<EEngineType>) => {
           this.engineType = engineType.getValue();

            this.presetFormGroup.patchValue({ designLibrary: null, ruleset: null });
            this.variantPresetsFormArray.controls
                .forEach((formGroup: FormGroup<ITemplateVariantPresetForm>) => {
                    formGroup.patchValue({ designLibrary: null, ruleset: null });
                });
        });

        this.presetFormGroup.controls.channel.valueChanges.pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((channel: DropdownItem<EChannel>) => {
            // for variant preset, to get master pages and reset master page
            this.channel = channel.getValue();
            this.presetFormGroup.controls.masterPage.patchValue(null);
            this.variantPresetsFormArray.controls
                .forEach((formGroup: FormGroup<ITemplateVariantPresetForm>) => {
                    formGroup.controls.masterPage.patchValue(null);
                });

            // for redrawing template editor
            this.templateEditorDataService.channelSubject.next(this.channel);
        });

        this.presetFormGroup.controls.masterPage.valueChanges.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((masterPage: MasterPageModel) => this.onMasterPageChanged(masterPage));

        this.presetFormGroup.controls.showMasterPage.valueChanges.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((showMasterPage: boolean) => {
            const masterPageFile = showMasterPage && this.presetFormGroup.value.masterPage.files
                ? this.presetFormGroup.value.masterPage.files.preview.url
                : null;
            this.templateEditorDataService.setBackgroundUrl(masterPageFile);
        });

        this.presetFormGroup.controls.numberOfPages.valueChanges.pipe(
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((numberOfPages) => {
            this.templateEditorDataService.setNumberOfPages(numberOfPages.value);
        });
    }

    public onMasterPageChanged(masterPage: MasterPageModel): void {
        if (masterPage) {
            // update template editor
            const newTemplateSize = new StaticContentTemplateSizeModel(masterPage.pageSize.width, masterPage.pageSize.height,
                new TemplateMarginsModel(
                    masterPage.margins.marginTop, masterPage.margins.marginBottom,
                    masterPage.margins.marginStart, masterPage.margins.marginEnd
                ));

            this.templateEditorDataService.setTemplateSize(newTemplateSize);
            this.templateEditorDataService.setBackgroundUrl(
                this.presetFormGroup.value.showMasterPage && masterPage.files
                    ? masterPage.files.preview.url
                    : null
            );

            // update form data
            this.presetFormGroup.patchValue({
                numberOfPages: this.numberOfPagesOptions.find((option) => option.value === masterPage.pageCount)
            });
            this.sizeFormGroup.patchValue({
                width: masterPage.pageSize.width,
                height: masterPage.pageSize.height,
                margins: masterPage.margins
            });

        } else {
            this.templateEditorDataService.setBackgroundUrl(null);
        }
    }

    public onTagChanged(tag: string): void {
        this.tagOptions = tag && tag.length && tag.trim().length ? [new DropdownItem<string>(tag.trim(), tag.trim())] : [];
    }

    public onTemplateTypeSearchChanged(value: string): void {
        if (this.templateTypeSubscription && !this.templateTypeSubscription.closed) {
            this.templateTypeSubscription.unsubscribe();
            this.templateTypes = [];
        }

        this.templateTypeSubscription = this.templateTypeService
            .getTemplateTypes(100, 0, 'name', 'asc', value)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (result) => {
                    this.templateTypes = result.items;
                    this.changeDetectorRef.markForCheck();
                },
                error:Toaster.handleApiError
            });
    }

    public onTemplateTypeAdded(value: string): void {
        this.templateTypeSubscription?.unsubscribe();

        this.addTemplateTypeSubscription = this.templateTypeService.addNewTemplateType(value)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (templateType) => this.presetFormGroup.patchValue({templateType: [templateType]}),
                error: Toaster.handleApiError
            });
    }
}
