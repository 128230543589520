import {inject, Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

export enum EHtmlOptions {
    FULL = 'FULL',
    PREVIEW = 'PREVIEW'
}

@Pipe({standalone: true, name: 'safeNotificationHtml'})
export class SafeNotificationHtmlPipe implements PipeTransform {
    private sanitizer: DomSanitizer = inject(DomSanitizer);

    transform(html: string, type?: EHtmlOptions | string): SafeHtml | string{
        switch (type) {
            case EHtmlOptions.PREVIEW:
                // Get title content from html as string
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = html;
                const titleDiv = tempDiv.querySelector('.title');
                return titleDiv.textContent
            case EHtmlOptions.FULL:
            default:
                return this.sanitizeAndConvertSpecificAttributes(html);
        }

    }

    private sanitizeAndConvertSpecificAttributes(html: string): SafeHtml {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        // Create a new sanitizer configuration
        return this.sanitizer.sanitize(SecurityContext.HTML, html);
    }

}
