import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    BUTTON_TYPE,
    EColumnType,
    ESelectionMode,
    ESortOrder,
    FullModalConfig,
    FullModalService,
    IActionClickEvent,
    IItemClickEvent,
    ISortOptionEvent,
    ITableAction,
    ITableColumn,
    NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {GroupFormComponent, IGroupFormComponentData} from './group-form/group-form.component';
import {GroupModel} from '../../../models/api/group.model';
import {Subject, Subscription} from 'rxjs';
import {EGroupJobTypes, GroupService, IDeleteGroupJobData} from '../../../api/services/group.service';
import {Toaster} from '../../../classes/toaster.class';
import {ActivatedRoute, Router} from '@angular/router';
import {SortDirection} from '@angular/material/sort';
import {AppConstants} from '../../../app.constants';
import {filter, takeUntil} from 'rxjs/operators';
import {MatrixUrlParams} from '../../../models/ui/matrix-url-params.model';
import {UserIsAllowedToPipe} from '../../../pipes/user-is-allowed-to.pipe';
import {EJobStatus, JobModel} from '../../../models/api/job.model';
import {MonitoredJobsService} from '../../../api/services/monitored-updates/monitored-jobs.service';
import {DropdownItem} from '../../../models/ui/dropdown-item.model';
import {UserSettingsStorageService} from '../../../api/services/user-settings-storage.service';
import {RLTableComponent} from '../../../components/rl-base-component/rl-table.component';
import {PaginatorService} from '../../../components/paginator/paginator.service';

@Component({
    selector: 'rl-groups-component',
    templateUrl: 'groups.component.html',
    styleUrls: ['groups.component.scss'],
    providers: [PaginatorService]
})
export class GroupsComponent extends RLTableComponent implements OnInit, OnDestroy {
    public readonly tableId: string = 'groups-table';
    public readonly storageKey: string;

    public groupSubscription: Subscription;
    public groups: GroupModel[];
    public columns: ITableColumn[] = [
        {
            title: 'Name',
            key: 'name',
            type: EColumnType.DEFAULT,
            sortProperty: 'name'
        },
        {
            title: 'User count',
            key: 'userCount',
            sortProperty: 'userCount'
        }
    ];
    public actions: ITableAction[] = [];
    public pageIndex: number;
    public pageSize: number;
    public sortProperty: string;
    public sortOrder: SortDirection;
    public totalItemCount: number;

    public selectionMode = ESelectionMode;
    public pageSizeOptions =
        AppConstants.PAGE_SIZE_OPTIONS.map((option) => new DropdownItem(`${option}`, option));

    public permissions = AppConstants.PERMISSIONS;
    private onDestroySubject = new Subject<void>();
    public disableNextPage = true;

    constructor(private fullModalService: FullModalService,
                private groupService: GroupService,
                private router: Router,
                private route: ActivatedRoute,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private dialogService: NucDialogService,
                private monitoredJobService: MonitoredJobsService,
                private paginatorService: PaginatorService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);
    }

    public ngOnInit(): void {
        this.subscribeToRoute();
        this.setTableActions();

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination) => {
                if (this.pageSize !== pagination.pageSize || this.pageIndex !== pagination.pageIndex) {
                    this.pageIndex = pagination.pageIndex;
                    this.pageSize = pagination.pageSize;

                    this.updateUrl();
                }

                this.getGroups();
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private subscribeToRoute(): void {
        this.route.params.subscribe((params) => {
            this.pageIndex = params['pageIndex'] ? parseInt(params['pageIndex'], 10) : AppConstants.PAGE_INDEX_DEFAULT;
            this.sortOrder = params.sortOrder;
            this.sortProperty = params.sortProperty;
            this.searchValue = params.search;

            this.updateUrl();

            this.setPageIndex(this.pageIndex);
        });
    }

    private setTableActions(): void {
        if (this.userIsAllowedToPipe.transform(this.permissions.UPDATE_GROUPS)) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.EDIT);
        }

        if (this.userIsAllowedToPipe.transform(this.permissions.POST_GROUP_JOBS)) {
            this.actions.push(AppConstants.TABLE_ACTION_TYPES.DELETE);
        }
    }

    public updateUrl(): void {
        this.router.navigate([AppConstants.CONTEXT_URL.SETTINGS, AppConstants.CONTEXT_URL.CONTENT_GROUPS, this.getMatrixUrl()], {replaceUrl: true});
    }

    private getMatrixUrl(): MatrixUrlParams {
        return new MatrixUrlParams(this.pageIndex, null, this.sortProperty, this.sortOrder, this.searchValue);
    }

    /**
     * Set page index, default the first page
     *
     * @param {number} [pageIndex]
     */
    public setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex); // reset pageIndex
    }

    private getGroups(): void {
        this.groupSubscription?.unsubscribe();

        this.groupSubscription = this.groupService.getGroups(this.pageSize, (this.pageIndex - 1) * this.pageSize,
            this.sortProperty, this.sortOrder, this.searchValue)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(
                (result) => {
                    this.groups = result.items;
                    this.totalItemCount = result.total;
                    this.disableNextPage = this.pageSize * this.pageIndex >= this.totalItemCount;
                },
                Toaster.handleApiError
            );
    }

    public openGroupForm(group?: GroupModel): void {
        const modalData = {group} as IGroupFormComponentData;
        const config = new FullModalConfig(
            group ?
                'Edit content group' :
                'Add new content group',
            group ?
                'Edit the information for the selected content group.' :
                'Fill in the information below to create a new content group. When created, permissions can be activated and users can be assigned.',
            modalData);
        config.confirmClose = true;
        this.fullModalService.open(GroupFormComponent, config).afterClosed().pipe(
            filter((result) => !!result)
        ).subscribe(() => this.getGroups());
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        this.sortProperty = event.column?.sortProperty || '';
        this.sortOrder = event.column?.sortProperty ? (event.sortOrder === ESortOrder.ASC ? 'asc' : 'desc') : '';

        this.updateUrl();
    }

    public onTableRowClicked(event: IItemClickEvent): void {
        this.router.navigate([AppConstants.CONTEXT_URL.SETTINGS, AppConstants.CONTEXT_URL.CONTENT_GROUPS, event.item._id]);
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        if (event.action === AppConstants.TABLE_ACTION_TYPES.EDIT) {
            this.openGroupForm(event.item as GroupModel);
        }
        if (event.action === AppConstants.TABLE_ACTION_TYPES.DELETE) {
            this.openDeleteDialog(event.item as GroupModel);
        }
    }

    private openDeleteDialog(group: GroupModel): void {
        const config = new NucDialogConfigModel('Delete content group', `Please confirm that you wish to delete ${group.name}.`);
        const deleteDialog = this.dialogService.openDialog(config);

        config.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => deleteDialog.close());
        config.addAction('Delete', BUTTON_TYPE.DESTRUCTIVE).subscribe(() => {
            deleteDialog.close();
            const jobData = {groupIds: [group._id]} as IDeleteGroupJobData;
            this.groupService.postJob(EGroupJobTypes.DELETE_GROUPS, jobData).subscribe(
                (result) => {
                    this.monitoredJobService.getItemMonitor(result._id)
                        .subscribe((jobModel: JobModel) => {
                            if (jobModel.status === EJobStatus.DONE) {
                                this.getGroups();
                            }
                        });
                },
                (error) => Toaster.handleApiError(error));
            // TODO: Implement polling / socket when we have the new notifications
        });
    }
}
