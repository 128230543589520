import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {StaticContentRulesetModel} from '../../modules/static-content-rulesets/models/api/static-content-ruleset.model';
import {EInsertLocation} from '@relayter/rubber-duck';
import {ApiService} from './api.service';
import {ApiPatchRequest} from '../requests/api-patch.request';

@Injectable({
    providedIn: 'root'
})
export class StaticContentRulesetApiService extends ApiService<StaticContentRulesetModel> {
    constructor() {
        super([ApiConstants.API_GROUP_STATIC_CONTENT_RULESETS], StaticContentRulesetModel);
    }

    public orderRulesetItems(formatRulesetId, targetId, sourceId, insertLocation?: EInsertLocation): Observable<StaticContentRulesetModel> {
        const body: Record<string, any> = {
            targetId,
            sourceId
        };
        if (insertLocation) {
            body.insertLocation = insertLocation;
        }
        const request = new ApiPatchRequest(this.groups, StaticContentRulesetModel, formatRulesetId, body, ApiConstants.API_METHOD_ORDER);
        return this.apiRequestService.patch(request);
    }
}
