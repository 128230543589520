import {Component, OnDestroy, OnInit} from '@angular/core';
import {CampaignModel} from '../../../../models/api/campaign.model';
import {ActivatedRoute, Router} from '@angular/router';
import {
    CampaignService,
    ECampaignJobTypes,
    ICampaignUpdateAllPackagesJobData
} from '../../../../api/services/campaigns.service';
import {Toaster} from '../../../../classes/toaster.class';
import {ARLogger} from '@relayter/core';
import {AppConstants} from '../../../../app.constants';
import {RLDatePipe} from '../../../../pipes/rl-date.pipe';
import {filter} from 'rxjs/operators';
import {UserIsAllowedToPipe} from '../../../../pipes/user-is-allowed-to.pipe';
import {RLBaseComponent} from '../../../../components/rl-base-component/rl-base.component';
import {TabBarRoutableItem} from '../../team-settings/tab-bar-routable-item.model';
import {ITabBarItem} from '@relayter/rubber-duck/lib/modules/tab-bar/config/tab-bar-item.interface';
import {
    IPublicationFormComponentData,
    PublicationFormComponent
} from '../../../../forms/publication-form/publication-form.component';
import {CampaignDetailService} from './campaign-detail.service';
import {Subject} from 'rxjs';
import {
    IPackagesFormComponentData,
    PackagesFormComponent
} from '../../../../forms/packages-form/packages-form.component';
import {EJobStatus, JobModel} from '../../../../models/api/job.model';
import {
    BUTTON_TYPE,
    FullModalConfig,
    FullModalService,
    NucDialogConfigModel,
    NucDialogService
} from '@relayter/rubber-duck';
import {PublicationsService} from '../../../../api/services/publications.service';
import {
    IPackageExportFormComponentData,
    PackageExportFormComponent
} from '../../../../forms/package-export-form/package-export-form.component';
import {MonitoredJobsService} from '../../../../api/services/monitored-updates/monitored-jobs.service';

@Component({
    selector: 'rl-campaigns-detail-component',
    templateUrl: 'campaigns-detail.component.html',
    styleUrls: ['campaigns-detail.component.scss'],
    providers: [CampaignDetailService]
})

export class CampaignsDetailComponent extends RLBaseComponent implements OnInit, OnDestroy {
    public tabs;
    public activeTab: TabBarRoutableItem;
    private publicationsTab = new TabBarRoutableItem(
        'Publications',
        [],
        []
    );

    private packagesTab = new TabBarRoutableItem(
        'Packages',
        [],
        []
    );

    public campaign: CampaignModel = new CampaignModel();
    public dateFormats = RLDatePipe.dateFormats;

    private onDestroySubject = new Subject<void>();

    constructor(private router: Router,
                private route: ActivatedRoute,
                private campaignService: CampaignService,
                private publicationsService: PublicationsService,
                private monitoredJobsService: MonitoredJobsService,
                private fullModalService: FullModalService,
                private campaignDetailService: CampaignDetailService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private dialogService: NucDialogService) {
        super();
    }

    /**
     * On authenticated init, get the config
     */
    public ngOnInit(): void {
        this.campaign = this.route.snapshot.data.campaign;

        this.initTabs();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initTabs(): void {
        const campaignId = this.route.snapshot.params['campaign_id'];
        this.publicationsTab.url = [AppConstants.CONTEXT_URL.CAMPAIGNS, campaignId, 'publications'];
        this.packagesTab.url = [AppConstants.CONTEXT_URL.CAMPAIGNS, campaignId, 'packages'];

        this.tabs = [this.publicationsTab, this.packagesTab];
        this.selectActiveTabFromRoute(this.router.url);
    }

    private selectActiveTabFromRoute(url: string): void {
        if (url.startsWith(`/${this.packagesTab.urlString}`)) {
            this.onActiveTabChanged(this.packagesTab);
        } else {
            this.onActiveTabChanged(this.publicationsTab);
        }
    }

    public onActiveTabChanged(newTab: ITabBarItem): void {
        this.activeTab = this.tabs.find((tab) => tab.title === newTab.title);
        this.router.navigate(this.activeTab.url, {skipLocationChange: true});
   }

    public openCreatePublicationModal(): void {
        const modalData: IPublicationFormComponentData = {campaignId: this.campaign._id};
        const modalConfig = new FullModalConfig('Add publication', 'Setup your publication details.', modalData);
        modalConfig.confirmClose = true;

        const modal = this.fullModalService.open(PublicationFormComponent, modalConfig);
        modal.afterClosed().pipe(filter((refresh: boolean) => refresh)).subscribe(() => this.campaignDetailService.onPublicationAdded());
    }

    public openCreatePackageModal(): void {
        const modalData: IPackagesFormComponentData = {campaignId: this.campaign._id};
        const modalConfig = new FullModalConfig('Add packages', 'Select package setup(s) to add package(s).', modalData);
        const modal = this.fullModalService.open(PackagesFormComponent, modalConfig);
        modal.afterClosed().pipe(filter((jobId) => !!jobId)).subscribe((jobId) => {
            this.monitoredJobsService.getItemMonitor(jobId)
                .subscribe((jobModel: JobModel) => {
                    if (jobModel.status === EJobStatus.DONE) {
                        this.campaignDetailService.onPackageAdded();
                    }
                });
        });
    }

    /**
     * When pressed on campaign setup and briefing navigate
     */
    public campaignSetupBriefingClicked(): void {
        this.router.navigate(['briefing'], {relativeTo: this.route});
    }

    public openExportModal(): void {
        const modalData: IPackageExportFormComponentData = {campaignId: this.campaign._id};
        const modalConfig = new FullModalConfig('Export packages',
            'Select the package type and export format of the items you wish to export.', modalData);
        this.fullModalService.open(PackageExportFormComponent, modalConfig);
    }

    public openRefreshConfirmationDialog(): void {
        const dialog = new NucDialogConfigModel('Update all campaign packages',
            'Are you sure you want to update all the campaign packages? Only new publication items will be added to the ' +
            'campaign package. Existing package items will not be affected.');
        const confirmDialog = this.dialogService.openDialog(dialog);
        dialog.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => confirmDialog.close());
        dialog.addAction('Update', BUTTON_TYPE.PRIMARY).subscribe(() => {
            confirmDialog.close();
            this.refreshPackages();
        });
    }

    public refreshPackages(): void {
        const jobData = {campaignId: this.campaign._id} as ICampaignUpdateAllPackagesJobData;
        this.campaignService.postJob(ECampaignJobTypes.CAMPAIGN_UPDATE_ALL_PACKAGES_JOB, jobData)
            .subscribe(job => {
                ARLogger.debug('Campaign packages update job scheduled: ' + job.status);
            }, error => Toaster.handleApiError(error));
    }

}
