<nuc-button-bar>
    @if (permissions.POST_OAUTH_CLIENT | userIsAllowedTo) {
        <nuc-button-primary text="Add OAuth client" (click)="openOAuthClientModal()"></nuc-button-primary>
    }
</nuc-button-bar>
<nuc-table emptyStateTitle="No OAuth clients yet"
           [items]="oauthClients"
           [columns]="columns"
           [loading]="!subscription?.closed"
           [selectionMode]="selectionMode"
           [tableId]="tableId"
           (sortOptionChange)="onSortOptionChanged($event)"
           (itemClicked)="onItemClicked($event)"
           [prefixStorageKey]="storageKey">
    <ng-container button-bar-right>
        <paginator [viewId]="tableId"
                   [disableNextPage]="disableNextPage"
                   [loading]="!subscription?.closed">
        </paginator>
    </ng-container>
</nuc-table>
