<div>
    <div class="connection-details-header">
        <h1>Connection details</h1>
        @if (permissions.PATCH_CONNECTION | userIsAllowedTo) {
            <nuc-button-secondary
                (click)="editConnection()"
                [disabled]="loading"
                text="Edit">
            </nuc-button-secondary>
        }
    </div>
    @if (connection) {
        <div class="connection-details">
            <div class="left">
                <img class="logo" [src]="logo">
            </div>
            <div class="properties-container">
                <div class="properties">
                    <div class="field">
                        <p class="body-strong">Name</p>
                        <p>{{ connection.name }}</p>
                    </div>
                    <div class="field">
                        <p class="body-strong">API Key</p>
                        <key-viewer [key]="connection.apiKey"
                                    [disabled]="loading">
                        </key-viewer>
                    </div>
                    <div class="field">
                        <p class="body-strong">Global Location Numbers (GLN)</p>
                        <p>{{ connection?.globalLocationNumbers?.length ?
                            (connection.globalLocationNumbers | join) : '-' }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    }
    <hr>
    <div class="connection-details-header">
        <h1>Image & product synchronisation details</h1>
        @if (permissions.PATCH_CONNECTION | userIsAllowedTo) {
            <nuc-button-secondary
                text="Add item"
                icon="nucicon_dots_vertical"
                iconPosition="end"
                [matMenuTriggerFor]="menu">
            </nuc-button-secondary>
            <mat-menu #menu="matMenu" class="menu-items-container">
                @for (menuItem of menuItems; track menuItem) {
                    <button mat-menu-item
                            (click)="onMenuItemClicked(menuItem.property)"
                            [disableRipple]="true">
                        Add {{ menuItem.title }}
                    </button>
                }
            </mat-menu>
        }
    </div>
    <div class="synchronisations">
        <div class="items">
            @if (connection) {
                @if (connection?.synchronisations?.images?.length || connection?.synchronisations?.products?.length) {
                    @for (sync of connection?.synchronisations?.images; track $index) {
                        <div class="item">
                            <div class="properties">
                                <div>
                                    <p class="body-strong">Image sync enabled</p>
                                    <i [ngClass]="sync.enabled ? 'green' : 'snow'" class="nucicon_check_round_fill"></i>
                                </div>
                                <div>
                                    <p class="body-strong">Regular expression</p>
                                    <p>{{ (sync.regexFilter | regexDisplayPipe | nullUndefinedFormatter ) }}</p>
                                </div>
                                <div>
                                    <p class="body-strong">Product datafield</p>
                                    <p>{{ sync.productDataField?.name | nullUndefinedFormatter }}</p>
                                </div>
                                <div>
                                    <p class="body-strong">Remove leading zeros</p>
                                    <i [ngClass]="sync.removeLeadingZeros ? 'green' : 'snow'" class="nucicon_check_round_fill"></i>
                                </div>
                                <nuc-button-bar class="actions">
                                    @if (permissions.PATCH_CONNECTION | userIsAllowedTo) {
                                        <nuc-button-secondary icon="nucicon_edit"
                                                              (click)="onEditSynchronisationClicked(sync._id)"
                                                              nucTooltip="Edit item">
                                        </nuc-button-secondary>
                                    }
                                    @if (permissions.DELETE_CONNECTION | userIsAllowedTo) {
                                        <nuc-button-secondary icon="nucicon_trash_fill"
                                                              (click)="openDeleteDialog(sync._id)"
                                                              nucTooltip="Delete item">
                                        </nuc-button-secondary>
                                    }
                                </nuc-button-bar>
                            </div>
                        </div>
                    }
                    @for (sync of connection?.synchronisations?.products; track $index) {
                        <div class="item">
                            <div class="properties">
                                <div>
                                    <p class="body-strong">Product sync enabled</p>
                                    <i [ngClass]="sync.enabled ? 'green' : 'snow'" class="nucicon_check_round_fill"></i>
                                </div>
                                <div>
                                    <p class="body-strong">Mapped fields</p>
                                    <p>{{ sync.mapping.length || '-' }}</p>
                                </div>
                                <div>
                                    <p class="body-strong">Product datafield</p>
                                    <p>{{ sync.productDataField?.name | nullUndefinedFormatter }}</p>
                                </div>
                                <div>
                                    <p class="body-strong">Remove leading zeros</p>
                                    <i [ngClass]="sync.removeLeadingZeros ? 'green' : 'snow'" class="nucicon_check_round_fill"></i>
                                </div>
                                <nuc-button-bar class="actions">
                                    @if (permissions.PATCH_CONNECTION | userIsAllowedTo) {
                                        <nuc-button-secondary icon="nucicon_edit"
                                                              (click)="onEditSynchronisationClicked(sync._id)"
                                                              nucTooltip="Edit item">
                                        </nuc-button-secondary>
                                    }
                                    @if (permissions.DELETE_CONNECTION | userIsAllowedTo) {
                                        <nuc-button-secondary icon="nucicon_trash_fill"
                                                              (click)="openDeleteDialog(sync._id)"
                                                              nucTooltip="Delete item">
                                        </nuc-button-secondary>
                                    }
                                </nuc-button-bar>
                            </div>
                        </div>
                    }
                } @else {
                    <nuc-empty-state-component imageUrl="/assets/images/general_empty_state.svg"
                                               title="Add your first synchronisation"
                                               [small]="true">
                    </nuc-empty-state-component>
                }
            } @else {
                <rl-loading-indicator size="large"></rl-loading-indicator>
            }
        </div>
    </div>
</div>
