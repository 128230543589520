import {inject, Injectable} from '@angular/core';
import {RelayterTableDataProvider} from '../../classes/relayter-table-data-provider.service';
import {UserModel} from '../../models/api/user.model';
import {UsersApiService} from '../services/users.api.service';
import {Subscription} from 'rxjs';
import {TableSortOptions} from '../table-sort-options';
import {finalize} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Toaster} from '../../classes/toaster.class';

@Injectable()
export class UserDataProvider extends RelayterTableDataProvider<UserModel> {
    private usersApiService = inject(UsersApiService);
    private usersSubscription: Subscription;

    public groupId: string;

    constructor(public sortOptions: TableSortOptions) {
        super(sortOptions);
    }

    public retrieveData(): void {
        if (this.usersSubscription && !this.usersSubscription.closed) {
            this.usersSubscription.unsubscribe();
        }

        this.loading = true;

        this.usersApiService.getUsers(this.sortOptions, this.apiCursor.getCursor(this.pageIndex), this.pageSize,
            null, this.groupId, this.searchValue)
            .pipe(
                finalize(() => this.loading = false),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: (result) => {
                    this.data = result.items;
                    this.disableNextPage = !result.hasNext;
                    if (this.data.length > 0) {
                        this.setCursor();
                    }
                },
                error: Toaster.handleApiError
            });
    }
}
